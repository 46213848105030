import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { analyticsSelector, answersSelector, assessmentIdSelector, assessmentResultSelector, getAssessmentAnalytics, getAssessmentQuestions, getAssessmentResult, questionsSelector } from "../../services/AssessmentSlice";
import { useNavigate } from "react-router-dom";

const Analytics = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const analytics = useSelector(analyticsSelector).finalAnalytics;
    const startTime = useSelector(analyticsSelector).minTime;
    const endTime = useSelector(analyticsSelector).maxTime;
    const totalDuration = useSelector(analyticsSelector).timeInSeconds;
    const assessmentId = useSelector(assessmentIdSelector);
    const assessmentResult = useSelector(assessmentResultSelector);
    const questions = useSelector(questionsSelector);
    const answers = useSelector(answersSelector);
    const [groupedData, setGroupedData] = useState({});
    const [categories, setCategories] = useState([]);
    const [data, setData] = useState([]);
    const [correctedAnswers, setCorrectedAnswers] = useState({});

    const lookUpTable = {
      "relevance": "Relevance",
      "clarity": "Clarity",
      "roleAlignment": "Role Alignment",
      "detail": "Details",
      "demonstrationOfSkills": "Demonstration of Skills",
      "problemSolvingChallenges": "Problem Solving Ability",
      "impact": "Impact",
      "teamCollaboration": "Team Spirit",
      "communicationSkills": "Communication Skills",
    }
    
    useEffect(() => {
      if(assessmentId){
        dispatch(getAssessmentAnalytics(assessmentId));
        dispatch(getAssessmentResult(assessmentId));
        dispatch(getAssessmentQuestions(assessmentId));
      } else {
        navigate('/assessment');
      }
    }, [dispatch, assessmentId, navigate])

    useEffect(() => {
      if(assessmentResult){
        const result = assessmentResult?.metadata;
        if(result){
          const keys = Object.keys(result);
          let finalResult = {};
          let xAxis = [];
          let correctedAns = {};
          keys.sort((a, b) => {
            const numA = parseInt(a.match(/\d+/)[0]);
            const numB = parseInt(b.match(/\d+/)[0]);
            
            if (numA === numB) {
              return a.localeCompare(b);
            } else {
              return numA - numB;
            }
          }).forEach(key => {
            xAxis.push(key);
            let params = Object.keys(result[key]);
            params.forEach(param => {
              if(param !== 'correctedAnswer'){
                if(Object.keys(finalResult).includes(param)){
                  finalResult[param].push(parseInt(String(result[key][param]['percentage']).replace("%", "")) || 0)
                } else {
                  finalResult[param] = [parseInt(String(result[key][param]['percentage']).replace("%", "")) || 0];
                }
              } else {
                correctedAns[key] = result[key][param];
              }
            })
          })
          setGroupedData(finalResult);
          setCategories(xAxis);
          setCorrectedAnswers(correctedAns);
        }
      }
    }, [assessmentResult]);

    useEffect(() => {
        if(analytics){
            let analysedData = [];
            const fixedOffset = new Date(analytics?.[Object.keys(analytics)?.[0]]?.[0]).getTime();
            Object.keys(analytics).sort((a, b) => {
              const numA = parseInt(a.match(/\d+/)[0]);
              const numB = parseInt(b.match(/\d+/)[0]);
              
              if (numA === numB) {
                return a.localeCompare(b);
              } else {
                return numA - numB;
              }
            }).forEach(item => {
                if(analytics[item]){
                  analysedData.push({
                    x: item,
                    y: analytics[item].map(d => Math.round((new Date(d).getTime() - fixedOffset)/(1000)))
                  })
                }
            })
            let newData = [];
            analysedData.forEach(item => {
              const { x, y } = item;
              
              // Split y values into pairs
              for (let i = 0; i < y.length; i += 2) {
                newData.push({ x, y: [y[i], y[i + 1]] });
              }
            });
            setData(newData);
        }
    }, [analytics])

    const secondsToHMS = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
    
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    return(
        <div>
            <h2 style={{textAlign: 'center'}}>Assessment Analytics</h2>
            <Grid container spacing={2} style={{marginTop: 20}}>
              <Grid item xs={12} md={6} lg={6} style={{padding: 20}}>
                <Grid style={{border: 'solid 1px #000', borderRadius: 20, textAlign: 'center'}}>
                  <h3>Start Time</h3>
                  <h1>{new Date(startTime).toLocaleString()}</h1>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{padding: 20}}>
                <Grid style={{border: 'solid 1px #000', borderRadius: 20, textAlign: 'center'}}>
                  <h3>End Time</h3>
                  <h1>{new Date(endTime).toLocaleString()}</h1>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{padding: 20}}>
                <Grid style={{border: 'solid 1px #000', borderRadius: 20, textAlign: 'center'}}>
                  <h3>Total Duration</h3>
                  <h1>{secondsToHMS(totalDuration)}</h1>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{padding: 20}}>
                <Grid style={{border: 'solid 1px #000', borderRadius: 20, textAlign: 'center'}}>
                  <h3>Average Duration</h3>
                  <h1>{secondsToHMS(Math.round(totalDuration/10))}</h1>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} style={{padding: 10}}>
                  <h3>Timeline of answers</h3>
                  <Chart 
                      series={[
                          {
                              data: data
                          }
                      ]}
                      options={{
                          chart: {
                            height: 390,
                            type: 'rangeBar',
                            zoom: {
                              enabled: false
                            },
                            selection: {
                              enabled: false
                            }
                          },
                          colors: ['#0074E4', '#FFA500'],
                          plotOptions: {
                            bar: {
                              horizontal: true,
                              // dumbbellColors: [['#EC7D31', '#36BDCB']]
                            }
                          },
                          fill: {
                            type: 'gradient',
                            gradient: {
                              gradientToColors: ['#FFA500'],
                              inverseColors: false,
                              stops: [0, 100]
                            }
                          },
                          grid: {
                            xaxis: {
                              lines: {
                                show: false
                              }
                            },
                            yaxis: {
                              lines: {
                                show: true
                              }
                            }
                          }
                      }}
                      type="rangeBar"
                      width={'100%'}
                      height={300}
                  />
              </Grid>
              {groupedData !== undefined && <Grid item xs={12} sm={12} lg={12} style={{padding: 10}}>
                  <h3>Answer analytics</h3>
                  <Chart 
                      series={
                          Object.keys(groupedData)?.map(item =>  ({data: groupedData[item], name: lookUpTable[item]}))
                      }
                      options={{
                        chart: {
                          type: 'bar',
                          height: 430
                        },
                        plotOptions: {
                          bar: {
                            horizontal: false,
                            dataLabels: {
                              position: 'top',
                            },
                          }
                        },
                        dataLabels: {
                          enabled: false,
                          offsetX: -6,
                          style: {
                            fontSize: '12px',
                            colors: ['#fff']
                          }
                        },
                        stroke: {
                          show: true,
                          width: 1,
                          colors: ['#fff']
                        },
                        tooltip: {
                          shared: true,
                          intersect: false
                        },
                        xaxis: {
                          categories: categories,
                        },
                      }}
                      type="bar"
                      width={'100%'}
                      height={300}
                  />
              </Grid>}
              <Grid item>
                <h2 style={{textAlign: 'center'}}>Suggested answers</h2>
                {Object.keys(questions).map(question => (
                  <Grid>
                    {correctedAnswers[question] && <Grid style={{margin: 10, padding: 10, border: 'solid 1px #000', borderRadius: 20}}>
                      <p>Q: {questions[question]}</p>
                      <p>A1: {answers[question]}</p>
                      <p>A2: {correctedAnswers[question]}</p>
                    </Grid>}
                  </Grid>
                ))}
              </Grid>
            </Grid>
        </div>
    )

}

export default Analytics;