import { Box, Grid } from "@mui/material";
import { useState } from "react";
import Login from "./Login";
import Register from "./Register";
import { screenWidthSelector } from "../../services/ScreenSlice";
import { useSelector } from "react-redux";
import { theme } from "../../Theme";

const AuthModule = () => {
    const styles = {
        container: {
            background: theme.palette.common.white,
            position: 'relative',
            margin: '5vw 10vw',
            height: '80vh',
            marginTop: '10vh',
            textAlign: 'center'
        },
        overlay: {
            content: '',
            position: 'absolute',
            top: 0,
            left:0,
            zIndex: 0,
            height: '100%',
            width: '100%',
            background: theme.palette.primary.main,
            clipPath: 'polygon(0 0, 45% 0, 35% 100%, 0 100%)',
            transition: 'clip-path 0.3s ease-out'
        },
        animatedContainer: {
            content: '',
            position: 'absolute',
            top: 0,
            left:0,
            zIndex: 0,
            height: '100%',
            width: '100%',
            background: theme.palette.primary.main,
            clipPath: 'polygon(55% 0, 100% 0, 100% 100%, 65% 100%)',
            transition: 'clip-path 0.3s ease-out'
        },
        description: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0 5vw',
            transition: 'display 1s ease'
        },
        formWrap:{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 5vw',
            transition: 'display 1s ease'
        }
    }

    const [animate, setAnimate] = useState(false)
    const screenSize = useSelector(screenWidthSelector);
    
    const handleAnimation = () => {
        setAnimate(!animate)
    }

    return(
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box style={styles.container} boxShadow={5}>
                    {screenSize > 900 && <>
                        {animate ? 
                            <Box style={styles.animatedContainer}></Box>:
                            <Box style={styles.overlay}></Box>
                        }
                    </>}
                    {!animate ?
                        <Login animateFunction={handleAnimation}/> : <Register animateFunction={handleAnimation}/>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}

export default AuthModule;