import { Button, Grid } from "@mui/material";
import { PropTypes } from "prop-types";
import { useCallback, useEffect, useState } from "react";
import CustomFields from "../components/CustomFields/CustomFields";

const PVForm = ({
    form,
    buttonText,
    formData,
    setFormData,
    clickFunction,
}) => {
    const [disabled, setDisabled] = useState(true)

    const handleFormChange = (evt) => {
        var name = evt.target.name;
        var value = evt.target.value;

        setFormData({...formData, [name] : value})
    }

    const validateForm = useCallback(() => {
        for(var ele of form){
            if((ele.required === true && 
                (formData[ele.name] === null || 
                formData[ele.name] === undefined || 
                formData[ele.name] === '')) ||
                (ele.validation && !formData[ele.name]?.match(ele.validation))
            ){
                return true
            }
        }
        return false
    },[form, formData])

    useEffect(() => {
        setDisabled((prev) => validateForm())
    },[validateForm])

    return(
        <div>
            <Grid container>
                {form && form?.map((ele, i) => (
                    <Grid item key={i} xs={12} sm={12} md={12} lg={12} style={Boolean(ele.customStyle) ? ele.customStyle : {padding: '3vw 5vw 0 5vw'}}>
                        <CustomFields 
                            fieldDetails={ele} 
                            value={formData[ele.name]} 
                            formChangeFunction={handleFormChange}/>
                    </Grid>
                ))}
                <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <Button 
                        variant="contained" 
                        onClick={clickFunction} 
                        disabled={disabled} 
                        style={{margin: '2vw 0'}}>
                        {buttonText}
                    </Button>
                </Grid> 
            </Grid>
        </div>
    )
}

PVForm.propTypes = {
    form: PropTypes.array.isRequired,
    buttonText: PropTypes.string.isRequired,
    formData: PropTypes.array,
    setFormData: PropTypes.func,
    clickFunction: PropTypes.func.isRequired
}

export default PVForm;