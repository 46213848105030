import { Box} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import Overlay from "../Overlay";
import { theme } from "../../Theme";

const TemplateImage = ({index, details, onClickMethod}) => {
    const styles = {
        imageWrap: { 
            height: '370px',
            boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
            cursor: 'pointer',
            position: 'relative',
            transition: 'all ease 0.7s'
        },
        image: {
            width: '100%', 
            height: '100%', 
            objectFit: 'cover', 
            objectPosition: 'center',  
            border: `1px solid ${theme.palette.secondary.main}`, 
            borderRadius: '5px'
        },
        btnWrap: {
            display: 'flex', 
            height: '100%', 
            alignItems: 'center', 
            justifyContent: 'center', 
            transition: 'all ease 0.5s'
        },
        btn: {
            minWidth: '150px', 
            fontWeight: 'bolder', 
            maxHeight: '30px', 
            padding: '20px',
            textTransform: 'capitalize',
            transition: 'font-size ease 0.5s',
            borderRadius: 0
        }
    }

    const overlayProps = {
        selector: "TemplateImage",
        link: details.previewLink,
        method: onClickMethod
    }

    const [showOverlay, setShowOverlay] = useState(-1);

    return(
        <Box style={{...styles.imageWrap, ...{width : showOverlay === index ? '102%' : '100%' }}} 
            onMouseOver={() => (index !== undefined || index !== null) ? setShowOverlay(index) : setShowOverlay(-1)} 
            onMouseLeave={() => setShowOverlay(-1)}
            onClick={() => (index !== undefined || index !== null) ? '' : window.open(details.previewLink)}
        >
            <img alt='template' src={details.image} style={styles.image}/>
            {(index !== undefined || index !== null) ? showOverlay === index ? 
                <Overlay color={"white"} details={overlayProps}/>: ''
            : index}
            
        </Box>
    )
}

TemplateImage.propTypes = {
    index : PropTypes.number,
    details : PropTypes.shape({
        image: PropTypes.string.isRequired,
        previewLink: PropTypes.string
    }),
    onClickMethod : PropTypes.func
}

export default TemplateImage;