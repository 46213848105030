import { createSlice } from "@reduxjs/toolkit";
import { HTTPRequest } from "../Api";
import { setBody, setType } from "./NotificationSlice";

const assessmentSlice = createSlice({
    name: 'assessmentHandler',
    initialState: {
        assessments: [],
        questions: {},
        assessmentId: '',
        analytics: {},
        assessmentResult: {},
        answers: {}
    },
    reducers: {
        setAssessments: (state, action) => {
            state.assessments = action.payload;
        },
        setQuestions: (state, action) => {
            state.questions = action.payload;
        },
        setAssessmentId: (state, action) => {
            state.assessmentId = action.payload;
        },
        setAnalytics: (state, action) => {
            state.analytics = action.payload;
        },
        setAssessmentResult: (state, action) => {
            state.assessmentResult = action.payload;
        },
        setAnswers: (state, action) => {
            state.answers = action.payload;
        }
    }
})

export function getAssessments() {
    return async (dispatch) => {
        dispatch(HTTPRequest('GET', '/assessments', {}, (response) => {
            if(response){
                dispatch(setAssessments(response));
            }
        }, (error) => {
            console.log(error);
        }))
    }
}

export function getAssessmentQuestions(assessmentId) {
    return async (dispatch) => {
        dispatch(HTTPRequest('GET', `/assessments/questions/${assessmentId}`, {}, (response) => {
            dispatch(setQuestions(response.assessmentQuestions));
            dispatch(setAnswers(response.assessmentAnswers));
        }, (error) => {
            dispatch(setType('error'));
            dispatch(setBody('There was an error fetching the data'));
            console.log("There was an error", error);
        }))
    }
}

export function createNewAssessment(data) {
    let body = {...data, userId: '12345'};
    return(dispatch) => {
       dispatch( HTTPRequest('POST', '/assessments', body, (response) => {
            dispatch(getAssessments());
            dispatch(generateQuestions(response));
        }, (error) => {
            dispatch(setType('error'))
            dispatch(setBody(error.message))
            console.log("There was an error", error);
        }))
    }
}

export function generateQuestions(result) {
     return (dispatch) => {
        dispatch( HTTPRequest('GET', `/assessments/genassessment/${result.assessmentId}`, {}, () => {
            dispatch(getAssessments());
        }, null, null, true))
     }
}

export function sendEvent(assessmentId, eventType, eventFor) {
    return (dispatch) => {
        let body = {assessmentId, eventFor, eventType};
        dispatch(HTTPRequest('POST', '/assessmentanalytics/', body, null, null, null, true));
    }
}

export function getAssessmentAnalytics(assessmentId) {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', `/assessmentanalytics/${assessmentId}`, {}, (response) => {
            dispatch(setAnalytics(response));
        }, (error) => {
            console.log(error);
        }))
    }
}

export function syncAnswers(assessmentId, answers) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/assessments/answers/${assessmentId}`, {answers}, (response) => {
            dispatch(setType('success'));
            dispatch(setBody('Submitted answers successfully'));
            dispatch(getAssessmentResult(assessmentId));
        }, (error) => {
            console.log(error);
        }))
    }
}

export function getAssessmentResult(assessmentId) {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', `/assessments/assessmentresults/${assessmentId}`, {}, (response) => {
            dispatch(setAssessmentResult(response));
        }, (error) => {
            dispatch(setType('error'))
            dispatch(setBody(error.message))
        }))
    }
}

export function toggleArchive(assessmentId) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/assessments/archive/${assessmentId}`, {}, (response) => {
            dispatch(getAssessments());
        }))
    }
}

export function deleteAssessement(assessmentId) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/assessments/delete/${assessmentId}`, {}, (response) => {
            dispatch(getAssessments());
        }))
    }
}

export function generateAssessmentResults(assessmentId){
    return (dispatch) => {
        dispatch(HTTPRequest('GET', `/assessments/genresults/${assessmentId}`));
    }
}

export const { setAssessments, setQuestions, 
                setAssessmentId, setAnalytics, setAssessmentResult,
                setAnswers } = assessmentSlice.actions;
export const assessmentsSelector = (state) => state.assessmentHandler.assessments;
export const questionsSelector = (state) => state.assessmentHandler.questions;
export const answersSelector = (state) => state.assessmentHandler.answers;
export const assessmentIdSelector = (state) => state.assessmentHandler.assessmentId;
export const analyticsSelector = (state) => state.assessmentHandler.analytics;
export const assessmentResultSelector = (state) => state.assessmentHandler.assessmentResult;
export default assessmentSlice.reducer;