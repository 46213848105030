import { Button, Divider, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { useGoogleLogin } from "@react-oauth/google"
import { loginForm } from "../../forms/loginForm"
import { useDispatch, useSelector } from "react-redux"
import { forgotPassword, login } from "../../services/UserSlice"
import { useNavigate } from "react-router-dom"
import PVForm from "../../Custom/PVForm"
import { screenWidthSelector } from "../../services/ScreenSlice"
import PVForms from "../../Custom/PVForms"
import { theme } from "../../Theme"

const Login = ({animateFunction}) => {
    const styles = {
        description: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0 5vw',
            transition: 'display 1s ease'
        },
        formWrap:{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 5vw',
            transition: 'display 1s ease'
        },
        outlinedBtn: {
            margin: '3vw 0',
            background: 'transperent',
            color: theme.palette.primary.contrastText,
            border: `1px solid ${theme.palette.primary.contrastText}`,
        }
    }

    const [loginFormData, setLoginFormData] = useState({})
    const [forgotPasswordRecord, setForgotPasswordRecord] = useState({});
    const screenSize = useSelector(screenWidthSelector);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: (response) => {handleGoogleSuccess(response)},
        onError: (error) => {console.log(error)}
    })

    const handleGoogleSuccess = (gData) => {
        const requestOptions = {
            method: 'GET',
            header: {
                Authorization: `Bearer ${gData.access_token}`,
                Accept: 'application/json'
            }
        }

        fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${gData.access_token}`, requestOptions).then((response)=> {
            response.json().then(data => {
                dispatch(login({
                    userEmail: data.email,
                    loginType: 'GMAIL'
                }))

                navigate('/templates') 
            })
        })
    }

    const handleLogin = () => {
        dispatch(login(loginFormData));   
        // setFormData({});
    }

    return(
        <Grid container style={{position: 'relative', zIndex: 1, height: 'inherit'}}>
            <Grid item xs={12} sm={12} md={5} lg={5} style={screenSize > 900 ? styles.description : {display: 'none'}} >
                <Typography variant="h4" style={{color: theme.palette.secondary.light}}>
                    Don't have an account?
                </Typography>
                <Typography variant="h6" style={{color: theme.palette.secondary.main}}>
                    Create an account to start with a template to showcase your ideas
                </Typography>
                <Button variant="outlined" onClick={() => animateFunction()} style={styles.outlinedBtn}>Sign up</Button>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7} style={styles.formWrap}>
                <Typography variant="h2">Login in to Your Account</Typography>
                <PVForm 
                    form={[...loginForm, {
                        "selector": "TEXTBUTTON",
                        "customStyle": {display: 'flex', justifyContent: 'flex-end', marginRight: '5vw'},
                        "label": "Forgot Password?",
                        onClick: () => {setForgotPasswordRecord({open: true})}
                    }]} 
                    buttonText={'Sign In'} 
                    formData={loginFormData} 
                    setFormData={setLoginFormData} 
                    clickFunction={handleLogin}
                />
                <PVForms 
                    fields={[
                        {
                            label: 'Email',
                            property: 'userEmail',
                            required: true
                        }
                    ]}
                    record={forgotPasswordRecord}
                    onChangeRecord={setForgotPasswordRecord}
                    saveLabel={'Submit'}
                    onSaveClicked={() => {
                        dispatch(forgotPassword(forgotPasswordRecord.editRecord, () => setForgotPasswordRecord({})))
                    }}
                    title="Forgot Password?"
                />
                <Divider width="100%"/>  
                <Button 
                    variant="outlined" 
                    style={{margin: '3vw 0'}} 
                    onClick={() => handleGoogleLogin()}>
                        Sign in with Google
                </Button>
                {screenSize <= 900 && <Button variant="text" style={{fontSize: 12, color: '#007BFF'}} onClick={() => animateFunction()}>Sign Up Instead</Button>}
            </Grid> 
        </Grid>
    )
}

export default Login;