import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import PropTypes from "prop-types";

const PVConfirm = ({open, title, confirmMessage,
                    saveLabel, cancelLabel,
                    saveClicked, cancelClicked}) => {

    return(
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{confirmMessage}</DialogContent>
            <DialogActions>
                <Grid style={{width: '100%', textAlign: 'center'}}>
                    <Button variant="outlined" style={{margin: 10}} onClick={(e) => {if(typeof(saveClicked)==='function'){saveClicked()}}}>{saveLabel || 'Yes'}</Button>
                    <Button variant="outlined" style={{margin: 10}} onClick={(e) => {if(typeof(cancelClicked)==='function'){cancelClicked()}}}>{cancelLabel || 'No'}</Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

PVConfirm.propTypes = {
    /**
     * @param open
     * 
     * Has to be a boolean, will be checked to see if the
     * confirm dialog has to be shown.
     */
    open: PropTypes.bool.isRequired,
    /**
     * @param title
     * 
     * Has to be a string, will be used to show the title
     * of the dialog.
     */
    title: PropTypes.string.isRequired,
    /**
     * @param confirmMessage
     * 
     * Has to be a string, will be shown in tha dialog
     * content as a message.
     */
    confirmMessage: PropTypes.string.isRequired,
    /**
     * @param saveLabel
     * @default "Yes"
     * 
     * Label for the positive button
     */
    saveLabel: PropTypes.string,
    /**
     * @param saveClicked
     * 
     * Function that will be called when the positive
     * button is clicked
     */
    saveClicked: PropTypes.func.isRequired,
    /**
     * @param cancelLabel
     * @default "No"
     * 
     * Label for the negative button.
     */
    cancelLabel: PropTypes.string,
    /**
     * @param cancelClicked
     * 
     * Function that will be called when the negative
     * button is clicked
     */
    cancelClicked: PropTypes.func.isRequired
}

export default PVConfirm;