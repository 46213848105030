import { useDispatch } from "react-redux";
import PVForms from "../../Custom/PVForms"
import { createReport } from "../../services/ReportsSlice";

const Report = ({record, setRecord}) => {

    const dispatch = useDispatch();

    const submit = () => {
        // dispatch(createApplication(newApplicationRecord.editRecord, () => setNewApplicationRecord({})));
        dispatch(createReport(record.editRecord, () => setRecord({})));
    }

    return(
        <PVForms 
            fields={[
                {
                    label: 'Report Type',
                    property: 'type',
                    required: true,
                    type: 'SELECT',
                    listValues: [
                        {
                            label: 'Bug',
                            value: 'bug'
                        },
                        {
                            label: 'Feature Request',
                            value: 'feature'
                        },
                        {
                            label: 'Other',
                            value: 'other'
                        }
                    ],
                    listLabelKey: 'label',
                    listValueKey: 'value'
                },
                {
                    label: 'Description',
                    property: 'description',
                    required: true,
                    type: 'TEXTAREA',
                    rows: 10
                },
                {
                    label: 'Screenshot',
                    property: 'screenshot',
                    type: 'FILE'
                }
            ]}
            record={record}
            onChangeRecord={setRecord}
            saveLabel={'Submit'}
            onSaveClicked={submit}
            title="Report"
        />
    )
}

export default Report;