import { createSlice } from "@reduxjs/toolkit";
import { HTTPRequest } from "../Api";

const coverLettersSlice = createSlice({
    name: 'coverLettersHandler',
    initialState: {
        coverLetters: [],
    },
    reducers: {
        setCoverLetters: (state, action) => {
            state.coverLetters = action.payload;
        }
    }
})

export function getCoverLetterRequests(background) {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', `/cl/`, {}, (response) => {
            dispatch(setCoverLetters(response));
        }, null, null, background))
    }
}

export function createCoverLetterRequest(data, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/cl/', data, (response) => {
            dispatch(getCoverLetterRequests());
            if(typeof(completed) === 'function'){ completed() }
        }))
    }
}

export function copyDataFromAssessment(data){
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/cl/copyfromasmt', data));
    }
}

export function rewriteCoverLetter(clId, data, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/cl/rewrite/${clId}`, data, (response) => {
            dispatch(getCoverLetterRequests());
            if(typeof(completed) === 'function'){ completed() }
        }))
    }
}

export function deleteCoverLetter(clId) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/cl/delete/${clId}`, {}, (response) => {
            dispatch(getCoverLetterRequests());
        }))
    }
}

export const { setCoverLetters } = coverLettersSlice.actions;

export const coverLettersSelector = (state) => state.coverLetterHandler.coverLetters;

export default coverLettersSlice.reducer;