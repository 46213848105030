export const stepperItems =  [
    {
        name: "Upload Resume",
        selector: "Upload"
    },
    {
        name: "Select Template",
        selector: "Template"
    },
    {
        name: "Process Resume",
        selector: "Review"
    },
    {
        name: "Template Access",
        selector: "Access"
    }
]