import template1 from '../assets/template2.png';
import template2 from '../assets/template3.png';
import template3 from '../assets/template4.png';
import template4 from '../assets/template5.png';

export const templatesList = [
    {
        image: template1,
        templateId: "2",
        previewLink: "https://template1.profilelink.click/"
    },
    {
        image: template2,
        templateId: "3",
        previewLink: "https://template2.profilelink.click/"
    },
    {
        image: template3,
        templateId: "4",
        previewLink: "https://template3.profilelink.click/"
    },
    {
        image: template4,
        templateId: "5",
        previewLink: "https://template4.profilelink.click/"
    }
]