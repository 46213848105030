import { Box, Grid } from '@mui/material'
import { templatesList } from '../../jsonEntries/templates'
import TemplateImage from '../ImageCards/TemplateImage'

const ImageGallery = ({nextStep}) => {
    const styles = {
    }

    return(
        <Box style={styles.container}>
            <Grid container spacing={4}>
                {templatesList.map((template, i) => (
                    <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                        <TemplateImage index={i} details={template} onClickMethod={() => nextStep({templateId: template.templateId})}/>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default ImageGallery;