import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
    name: 'loadHandler',
    initialState: {
        apisLoading: [],
    },
    reducers: {
        setApisLoading: (state, action) => {
            state.apisLoading = action.payload;
        }
    }
})

export const { setApisLoading } = loaderSlice.actions;

export const apisLoadingSelector = (state) => state.loadHandler.apisLoading;

export default loaderSlice.reducer;