import { Article, FileUpload, Phonelink, ThumbUp, Web } from "@mui/icons-material";
import { Box, Divider, Fab, Typography } from "@mui/material";
import { stepperItems } from "../../jsonEntries/stepperItems";
import { useEffect, useState } from "react";
import Steps from "./Steps";
import { useDispatch, useSelector } from "react-redux";
import { clearUnfinishedDoc, getUnfinishedDoc, selectTemplate, setUploadedDocumentId, unfinishedDocSelector, uploadedDocumentIdSelector } from "../../services/CreateTemplateSlice";
import { theme } from "../../Theme";

const Stepper = () => {
    const styles = {
        buttonSelected: {
            minHeight: '3vw', 
            height: '3vw', 
            minWidth: '3vw', 
            width: '3vw', 
            padding: '1vw',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            boxShadow: 'none'
        },
        buttonFaded: {
            minHeight: '3vw', 
            height: '3vw', 
            minWidth: '3vw', 
            width: '3vw', 
            padding: '1vw',
            border: `1px solid ${theme.palette.secondary.main}`,
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.light,
            boxShadow: 'none'
        },
        divider: {
            width: '600%', 
            height: '0', 
            marginTop: '1.5vw', 
            borderWidth: '1.5px'
        }
    }

    const [currentStep, setCurrentStep] = useState(0)
    const uploadedDocumentId = useSelector(uploadedDocumentIdSelector);
    const dispatch = useDispatch();

    const unfinishedDoc = useSelector(unfinishedDocSelector);

    useEffect(() => {
        dispatch(getUnfinishedDoc((doc) => {
            if(doc.status === 'NOT_RESUME' || doc.isResume === false){
                setCurrentStep(0);
            } else if(doc.status === 'DOCUMENT_UPLOADED'){
                setCurrentStep(1);
                dispatch(setUploadedDocumentId(doc._id));
            } else if (doc.status === 'PROCESSING') {
                setCurrentStep(2);
                dispatch(setUploadedDocumentId(doc._id));
            }
        }));
    }, [dispatch])

    const handleNext = (data) => {
        if(currentStep === 1 && Boolean(data?.templateId)){
            dispatch(selectTemplate(data?.templateId, uploadedDocumentId));
        }
        setCurrentStep(currentStep + 1)
    }

    const clearUnfinished = () => {
        dispatch(clearUnfinishedDoc(() => {
            setCurrentStep(0);
        }));
    }

    return(
        <Box>
            <Box style={{display: 'flex', backgroundColor: theme.palette.secondary.light, padding: '1vw', borderRadius: '15px'}}> 
                {stepperItems.map((ele, i) => (
                    <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '25%'}} key={i}>
                        <Box style={{display: 'flex'}}>
                            <Fab style={currentStep >= i ? styles.buttonSelected : styles.buttonFaded}>
                                {(() => {
                                    switch(ele.selector){
                                        case "Upload":
                                            return(
                                                <FileUpload style={{fontSize: '1.5vw'}} onClick={clearUnfinished}/>
                                            )
                                        case "Template":
                                            return(
                                                <Web style={{fontSize: '1.5vw'}} />
                                            )
                                        case "Review":
                                            return(
                                                <ThumbUp style={{fontSize: '1.5vw'}} />
                                            )
                                        case "Access":
                                            return(
                                                <Phonelink style={{fontSize: '1.5vw'}} />
                                            )
                                        default:
                                            return(
                                                <Article style={{fontSize: '1.5vw'}} />
                                            )
                                    }
                                })()}
                            </Fab>
                            {i < stepperItems.length - 1 ? 
                                <Divider style={{...styles.divider, ...{backgroundColor: currentStep > i ? theme.palette.primary.main : ''}}}/> :
                                ''
                            }
                        </Box>
                        <Typography variant="body2" style={{marginTop: '1vw', fontSize: 'calc(6px + 0.5vw)', textAlign: 'center', fontWeight: 'bold'}}>{ele.name}</Typography>
                    </Box> 
                ))}
            </Box>
            <Box style={{paddingTop: '2vw'}}>
                <Steps selector={stepperItems[currentStep]?.selector} successHandler={handleNext} document={unfinishedDoc} changeStep={setCurrentStep}/>
            </Box> 
        </Box>
    )
}

export default Stepper;