import { createSlice } from "@reduxjs/toolkit";
import { HTTPRequest } from "../Api";

const ApplicationsSlice = createSlice({
    name: 'applicationsHandler',
    initialState: {
        applications: []
    },
    reducers: {
        setApplications: (state, action) => {
            state.applications = action.payload;
        }
    }
})

export function getApplications(){
    return (dispatch) => {
        dispatch(HTTPRequest('GET', '/applications', {}, (response) => {
            dispatch(setApplications(response));
        }))
    }
}

export function createApplication(application, completed){
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/applications', application, (response) => {
            dispatch(getApplications());
            if(typeof(completed) === 'function'){
                completed();
            }
        }))
    }
}

export function updateApplication(id, application, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/applications/${id}`, application, (response) => {
            dispatch(getApplications());
            if(typeof(completed) === 'function'){
                completed();
            }
        }))
    }
}

export function deleteApplication(id, completed){
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/applications/delete/${id}`, {}, (response) => {
            dispatch(getApplications());
            if(typeof(completed) === 'function'){
                completed();
            }
        }))
    }
}

export function uploadDocument(formdata, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/telegramdoc/docupload', formdata, (data) => {
            if(typeof(completed) === 'function'){
                completed(data.signedUrl);
            }
        }, null, 'form'));
    }
}

export const { setApplications } = ApplicationsSlice.actions;

export const applicationsSelector = (state) => state.applicationsHandler.applications;

export default ApplicationsSlice.reducer;