import { createTheme } from "@mui/material";

export const theme =  createTheme({
    palette: {
      primary: {
        main: '#432818',        
        light: '#b4a9a3',
        dark: '#1b100a',  
        contrastText: '#fffefc' 
      },
      secondary: {
          main: '#e3d5ca',      
          light: '#f9f7f4',   
          dark: '#9f958d',  
          contrastText: '#191816'  
      },
      info: {
          main: '#007BFF',      
          light: '#e6f2ff',   
          dark: '#001933',
          contrastText: '#ffffff'    
      },
      error: {
          main: '#dc3545',      
          light: '#f8d7da',   
          dark: '#842029',    
      },
      warning: {
          main: '#ffc107',      
          light: '#fff3cd',   
          dark: '#664d03',    
      },
      success: {
          main: '#28a745',      
          light: '#d4edda',   
          dark: '#0c7d1e',    
      },
      text: {
          primary: '#343a40',   
          secondary: '#6c757d', 
          disabled: '#adb5bd',  
      },
      common: {
        black: '#191816',
        white: '#ffffff'
      }
    },
    typography: {
      h1: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: "calc(50px + 0.5vw)"
      },
      h2: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: "calc(25px + 0.5vw)"
      },
      h3: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: "calc(20px + 0.5vw)"
      },
      h4: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: "calc(15px + 0.5vw)"
      },
      h5: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: "calc(12px + 0.5vw)"
      },
      h6: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: "calc(8px + 0.5vw)"
      },
      body1: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: "calc(10px + 0.5vw)"
      },
      body2: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: "calc(8px + 0.5vw)"
      }
    },
    components: {
        MuiButton: {
            defaultProps: {
              variant: 'outlined'
            },
            styleOverrides: {
                outlined: {
                    padding: '2px 10px',
                    height: 40,
                    border: '1px solid',
                    borderRadius: "5px",
                },
                text: {
                  color: '#000',
                  textDecoration: 'underline'
                }
            }
        },
        MuiCard: {
          styleOverrides: {
            root: {
              ':hover': {
                backgroundColor: 'rgba(0,0,0,0.02)'
              }
            }
          }
        },
        MuiTextField: {
          defaultProps: {
            size: 'small',
            variant: 'outlined'
          }
        },
        MuiDrawer: {
          defaultProps: {
            variant: 'permanent'
          },
          styleOverrides: {
            root: {
              width: 80,
              position: 'relative',
              zIndex: 0
            },
            paper: {
              borderRight: 'none'
            }
          }
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: 'rgba(0,0,0,0.5)',
              color: '#FFF',
              padding: 10,
              fontSize: 15,
              fontFamily: 'sans-serif',
              fontWeight: 'bold'
            }
          }
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              color: '#000'
            }
          }
        }
    }
});
