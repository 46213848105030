import { createSlice } from "@reduxjs/toolkit";

const screenSlice = createSlice({
    name: "screenHandler",
    initialState: {
        screenWidth: 0,
        screenHeight: 0,
        env: 'prod'
    },
    reducers: {
        setScreenWidth: (state, action) => {
            state.screenWidth = action.payload;
        },
        setScreenHeight: (state, action) => {
            state.screenHeight = action.payload;
        },
        setEnv: (state, action) => {
            state.env = action.payload;
        }
    }
})

export const { setScreenWidth, setScreenHeight, setEnv } = screenSlice.actions;

export const screenWidthSelector = state => state.screenHandler.screenWidth;
export const screenHeightSelector = state => state.screenHandler.screenHeight;
export const envSelector = state => state.screenHandler.env;

export default screenSlice.reducer;