import { AppBar, Box, Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import { AddToQueue, Dashboard, HistoryEdu, ListAlt, Mail, Person, ViewModule, Work, WorkHistory } from "@mui/icons-material";
import { navItmes } from "../../jsonEntries/navItems";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "../../services/UserSlice";
import NavLogo from '../../assets/logo.png';
import Report from "../Dialogs/Report";
import ResetPassword from "./ResetPassword";
import MenuIcon from '@mui/icons-material/Menu';
import { screenWidthSelector } from "../../services/ScreenSlice";
import { theme } from "../../Theme";

const Navigation = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [open, setOpen] = useState(false);
    const [client, setClient] = useState({});
    const [reportRecord, setReportRecord] = useState({}); 
    const [resetPasswordRecord, setResetPasswordRecord] = useState({});
    const user = useSelector(userSelector);
    const location = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const drawerWidth = 240;
    const screenWidth = useSelector(screenWidthSelector);

    const styles = {
        list: {
            padding: 0,
        },
        listBtn: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            padding: '10px 0'
        }
    }

    const handleNavigation = (link, name) => {
        navigate(link)
        setTitle(name)
        setDrawerOpen(false);
    }

    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        window.location.reload();
    }

    const resetPassword = () => {
        setResetPasswordRecord({open: true});
        setOpen(false);
    }

    const list = () => (
        <div
          role="presentation"
          style={{marginTop: '8vh'}}
        >
          <List>
            {navItmes.map((item, index) => (
              <ListItem key={item.name}
                        onClick={() => handleNavigation(item.link, item.name)}
                        onKeyDown={() => handleNavigation(item.link, item.name)}>
                <ListItemIcon>
                    {(() => { switch(item.name) {
                                        case 'Dashboard' :
                                            return( <Dashboard fontSize="small" style={{padding: 5}}/> )
                                        case 'Templates': 
                                            return( <ViewModule fontSize="small" style={{padding: 5}}/> )
                                        case 'Create':
                                            return( <AddToQueue fontSize="small" style={{padding: 5}}/> )
                                        case 'Inbox':
                                            return( <Mail fontSize="small" style={{padding: 5}}/> )
                                        case 'Assessment':
                                            return (<Work fontSize="small" style={{padding: 5}}/>)
                                        case 'Cover Letter': 
                                            return (<HistoryEdu fontSize="small" style={{padding: 5}}/>)
                                        case 'Applications': 
                                            return (<WorkHistory fontSize="small" style={{padding: 5}}/>)
                                        default:
                                            return( <ListAlt style={{marginRight: '20px'}} /> )
                    }})()}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
        </div>
      );

    useEffect(() => {
        setTitle(navItmes.filter(item => item.link === location.pathname)?.[0]?.name);
    }, [location])

    useEffect(() => {
        if(user.tempPassword === true){
            setResetPasswordRecord({open: true});
        }
    }, [user])

    return(
        <>
            <Menu
                open={open}
                anchorReference="anchorPosition"
                disableAutoFocusItem
                anchorPosition={{left: client.x, top: client.y}}
                style={{zIndex: 10002}}
                onClose={() => setOpen(false)}
            >
                <MenuItem key={'report'} sx={{display: {xs: 'block', sm: 'none'}}} onClick={() => setReportRecord({open: true})}>Report</MenuItem>
                {user.loginType === 'EMAIL' && <MenuItem key={'resetpwd'} onClick={resetPassword}>Change Password</MenuItem>}
                <MenuItem key={'logout'} onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            <Report record={reportRecord} setRecord={setReportRecord} />
            <ResetPassword record={resetPasswordRecord} setRecord={setResetPasswordRecord} user={user}/>
            <AppBar elevation={0} style={{background: theme.palette.primary.main, zIndex: 10001}}>
                <Toolbar style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => setDrawerOpen(!drawerOpen)}
                            sx={{ display: { sm: 'none', xs: 'block' }, marginLeft: 0.2 }}
                        >
                            <MenuIcon style={{color: theme.palette.primary.contrastText}}/>
                        </IconButton>
                        <img alt="logo" src={NavLogo} width={45} height={'auto'} style={{cursor: 'pointer', marginRight: 10, marginLeft: -10, display: screenWidth > 600 ? 'block' : 'none'}} onClick={() => navigate('')}/>
                        <Typography variant="h4" sx={{marginLeft: {sm: 2, xs: 1}}}>{title}</Typography>
                    </Box>
                    <Box style={{display: 'flex',  alignItems: 'center'}}>
                        <Button variant="text" sx={{display: {sm: 'block', xs: 'none'}}} style={{color: '#FFF', textDecoration: 'none', textTransform: 'none'}} onClick={() => setReportRecord({open: true})}>Report</Button>
                        <Tooltip title="Coming soon" arrow sx={{display: {sm: 'block', xs: 'none'}}}>
                            <Button variant="text" style={{color: '#FFF', textDecoration: 'none', textTransform: 'none'}}>Upgrade</Button>
                        </Tooltip>
                        <IconButton onClick={(event) => {setOpen(true);setClient({x: event.clientX, y: event.clientY})}}>
                            <Person style={{color: theme.palette.primary.contrastText}}/>
                        </IconButton>
                        <Typography variant="h5">{user.displayName || ''}</Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer container={window.document.body}
                        variant="temporary"
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                            zIndex: 10000
                        }}
                        anchor='left' 
                        open={drawerOpen}
                        onClose={() => setDrawerOpen(false)}>
                    {list()}
                </Drawer>
            </nav>
            <Drawer sx={{display: {xs: 'none', sm: 'block'}}}>
                <Toolbar style={{background: theme.palette.primary.main, marginTop: -10}}></Toolbar>
                <Box style={{width: 80, height: '100%', background: theme.palette.primary.main, color: theme.palette.primary.main}}>
                    <List>
                        {navItmes.map((item, i) => (
                            <ListItem style={{...styles.list, backgroundColor: location.pathname === item.link && theme.palette.primary.light}} key={i}>
                                <ListItemButton style={styles.listBtn} onClick={() => handleNavigation(item.link, item.name)}>
                                    {(() => { switch(item.name) {
                                        case 'Dashboard' :
                                            return( <Dashboard fontSize="small" style={{padding: 5, color: location.pathname !== item.link ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText}}/> )
                                        case 'Templates': 
                                            return( <ViewModule fontSize="small" style={{padding: 5, color: location.pathname !== item.link ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText}}/> )
                                        case 'Create':
                                            return( <AddToQueue fontSize="small" style={{padding: 5, color: location.pathname !== item.link ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText}}/> )
                                        case 'Inbox':
                                            return( <Mail fontSize="small" style={{padding: 5, color: location.pathname !== item.link ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText}}/> )
                                        case 'Assessment':
                                            return (<Work fontSize="small" style={{padding: 5, color: location.pathname !== item.link ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText}}/>)
                                        case 'Cover Letter': 
                                            return (<HistoryEdu fontSize="small" style={{padding: 5, color: location.pathname !== item.link ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText}}/>)
                                        case 'Applications': 
                                            return (<WorkHistory fontSize="small" style={{padding: 5, color: location.pathname !== item.link ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText}}/>)
                                        default:
                                            return( <ListAlt style={{marginRight: '20px'}} /> )
                                    }})()}
                                    <Typography variant="body2" style={{color: location.pathname !== item.link ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText, fontSize: "calc(6px + 0.5vw)"}}>{item.name}</Typography>
                                </ListItemButton>
                            </ListItem>
                        ))} 
                    </List>
                </Box>
            </Drawer>
        </>
    )
}

export default Navigation;