import { Button, Grid, Typography } from "@mui/material"
import * as AboutMe from '../../lottie/aboutMe.json';
import * as WorkExp from '../../lottie/workExp.json';
import * as Projects from '../../lottie/projects.json';
import * as Contact from '../../lottie/contact.json';
import * as Skills from '../../lottie/skills.json';
import * as ShortDesc from '../../lottie/shortDesc.json';
import * as Education from '../../lottie/degree.json';
import * as Completed from '../../lottie/completed.json';
import * as NotResume from '../../assets/NotResume.json';
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PVTable from "../../Custom/PVTable";
import { getUnfinishedDoc, templatesSelector, unfinishedDocSelector } from "../../services/CreateTemplateSlice";

const Review = ({queue, changeStep}) => {

    const document = useSelector(unfinishedDocSelector);
    const templates = useSelector(templatesSelector);
    const dispatch = useDispatch();

    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        console.log("templatesChanged", templates, document._id);
        if(Boolean(templates.find((template) => template.documentData._id  === document._id))){
            setCompleted(true);
            changeStep(3);
        }
    }, [document, changeStep, templates, dispatch])

    useEffect(() => {
        setTimeout(() => {
            dispatch(getUnfinishedDoc());
        }, 1000)
    }, [dispatch])

    const lookUpData = {
        ABOUT_ME: "Generating a description about yourself",
        WORK_EXPERIENCE: "Generating your work experience",
        PROJECTS: "Generating the details about projects",
        CONTACT_INFORMATION: "Parsing the contact information",
        SKILLS_WITH_PERCENTAGE: "Getting the skills",
        SHORT_DESCRIPTION: "Generating a short description",
        EDUCATION: "Gettign the education details",
        COMPLETED: "Your portfolio site is ready, we'll be redirecting you there shortly."
    }

    const lottieFiles = {
        ABOUT_ME: AboutMe,
        WORK_EXPERIENCE: WorkExp,
        PROJECTS: Projects,
        CONTACT_INFORMATION: Contact,
        SKILLS_WITH_PERCENTAGE: Skills,
        SHORT_DESCRIPTION: ShortDesc,
        EDUCATION: Education,
        COMPLETED: Completed
    } 

    const status = {
        COMPLETED: 'Completed',
        PROCESSING: 'Processing',
        QUEUED: 'Queued',
        ERRORED: 'Errored'
    }

    if(completed){
        return(
            <Grid container style={{display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center'}} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: 'center', margin: '2vw 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Lottie animationData={lottieFiles['COMPLETED']} loop style={{width: 300, height: 300}}/>
                    <Typography variant="h2">Your portfolio site is ready, Click on the Button below to open it</Typography>
                    <Button style={{marginTop: 20}} onClick={() => {
                        const current = templates.find((template) => template.documentData._id  === document._id);
                        window.open(`https://${current.subDomain}`, '_blank');
                    }}>Open Portfolio</Button>
                </Grid>
            </Grid>
        )
    } else {
        return(
            // <Grid container style={{display: 'flex', alignItems: 'center'}}>
                // <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: 'center', margin: '2vw 0'}}>
                //     <Typography variant="h2" style={{...textStyles.header2, ...{marginBottom: '0.2vw', color: theme.light.info}}}>Hang in with us while your template is getting ready!</Typography>
                //     <Typography variant="body1" style={{...textStyles.body, ...{color: theme.light.info, opacity: 0.4}}}>This might take a few minutes. Meanwhile you can checkout the options to access your template</Typography>
                // </Grid>
            //     <Grid item xs={12} sm={12} md={6} lg={6}>
            //         <TemplateGeneration />
            //     </Grid>
            //     <Grid item xs={12} sm={12} md={6} lg={6}>
            //         <Box style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
            //             <UserVerification />
            //             <Divider style={{width: '80%', margin: '2.5vw 0'}}/>
            //             <TelegramQRScan />
            //         </Box>
            //     </Grid>
            // </Grid>
            <Grid container style={{display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center'}} spacing={2}>
                {document.status === 'PROCESSING' && <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: 'center', margin: '2vw 0'}}>
                    <Typography variant="h2">Hang in with us while your portfolio is getting ready!</Typography>
                    <Typography variant="body1">This might take a few minutes</Typography>
                </Grid>}
                {document.status === 'NO_RESUME' && <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: 'center', margin: '2vw 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Lottie animationData={NotResume} loop style={{width: 300, height: 300}}/>
                    <Typography variant="h2">We were not able to detect resume information</Typography>
                    <Button onClick={() => changeStep(0)}>Start Over</Button>
                </Grid>}
                {document.status === 'COMPLETED' && <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: 'center', margin: '2vw 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Lottie animationData={lottieFiles['COMPLETED']} loop style={{width: 300, height: 300}}/>
                    <Typography variant="h2">Your portfolio site is ready, we'll be redirecting you there shortly.</Typography>
                </Grid>}
                {/* {queue?.map((item, i) => (
                    <Grid item xs={12} sm={12} md={6} lg={3} key={i}>
                        <Card>
                            <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: item.status === 'COMPLETED' && 'rgba(0,255,0,0.5)'}}>
                                <Typography>{lookUpData[item.subType]}</Typography>
                                <Lottie animationData={lottieFiles[item.subType]} loop style={{width: 300, height: 300, marginBottom: '3vw'}}/>
                            </CardContent>
                        </Card>
                    </Grid>
                )
                )} */}
                <PVTable 
                    columns={[
                        {
                            label: 'Description',
                            property: 'description',
                            calculated: (row) => lookUpData[row.subType]
                        },
                        {
                            label: 'Status',
                            property: 'status',
                            calculated: (row) => status[row.status]
                        }
                    ]}
                    data={queue || []}
                    rowStyle={(row) => {
                        if (row.status === 'COMPLETED') {
                            return { backgroundColor: 'rgba(0, 255, 0, 0.5)' }; // Green color for completed status
                        }
                        if (row.status === 'QUEUED') {
                            return { backgroundColor: 'rgba(128, 128, 128, 0.5)' }; // Gray color for queued status
                        }
                        if (row.status === 'PROCESSING') {
                            return { backgroundColor: 'rgba(255, 255, 0, 0.5)' }; // Yellow color for processing status
                        }
                        if (row.status === 'ERRORED') {
                            return { backgroundColor: 'rgba(255, 0, 0, 0.5)' }; // Red color for error status
                        }
                    }}
                />
            </Grid>
        )
    }
}

export default Review;