import { Button, TextField } from "@mui/material";
import PropTypes from "prop-types";

const CustomFields = ({fieldDetails, value, formChangeFunction, focusEvents, style}) => {
    switch(fieldDetails?.selector){
        case 'TEXTFIELD':
                default:
            return(<TextField 
                label={fieldDetails?.label}
                name={fieldDetails?.name}
                type={fieldDetails?.type}
                value={value || ''}
                multiline={fieldDetails?.multiline}
                rows={fieldDetails?.rows}
                onChange={(event)=>{if(typeof(formChangeFunction)==='function'){formChangeFunction(event)}}}
                onFocus={(event) => {if(typeof(fieldDetails?.focusEvents)==='function'){fieldDetails?.focusEvents(fieldDetails?.property, 'FOCUS_START')}}}
                onBlur={(event) => {if(typeof(fieldDetails?.focusEvents)==='function'){fieldDetails?.focusEvents(fieldDetails?.property, 'FOCUS_END')}}}
                required={fieldDetails?.required}
                error={(fieldDetails?.required && (value === '' || value === null || value === undefined)) || (fieldDetails?.validation && !value?.match(fieldDetails?.validation)) ? true : false}
                fullWidth={fieldDetails.fullWidth ? fieldDetails.fullWidth : true}
                style={style}
                inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                }}
                InputLabelProps={{ shrink: fieldDetails?.shrink }}
            />)
        case 'TEXTBUTTON':
            return(<Button 
                variant="text"
                onClick={fieldDetails?.onClick}
                >
                    {fieldDetails.label}
                </Button>)
    }
}

CustomFields.propTypes = {
    fieldDetails: PropTypes.shape({
        property: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        multiline: PropTypes.bool,
        rows: PropTypes.number,
        required: PropTypes.bool,
        validation: PropTypes.string,
        focusEvents: PropTypes.func,
        fullWidth: PropTypes.bool
    }),
    value: PropTypes.string.isRequired,
    formChangeFunction: PropTypes.func.isRequired,
    focusEvents: PropTypes.func,
}

export default CustomFields;