import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";

const Settings = ({open}) => {

    return(
        <Dialog open={open} fullWidth>
            <DialogTitle>Settings</DialogTitle>
            <DialogContent>
                <Grid>
                    <Grid style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography>Allow multiple browsers to login at the same time</Typography>
                        <Button>Disabled</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )

}

export default Settings;