import { useDispatch } from "react-redux"
import PVForms from "../../Custom/PVForms"
import { resetPassword } from "../../services/UserSlice";

const ResetPassword = ({record, setRecord, user}) => {

    const dispatch = useDispatch();

    const submit = () => {
        dispatch(resetPassword(record.editRecord, () => {setRecord({})}));
    }

    return (
        <PVForms 
            fields={[
                {
                    label: "Old Password",
                    property: "oldPassword",
                    type: "password",
                    required: user.tempPassword !== true,
                    visible: user.tempPassword !== true
                },
                {
                    label: "New Password",
                    property: "newPassword",
                    type: "password",
                    required: true,
                    error: record.editRecord?.newPassword?.length < 8
                },
                {
                    label: "Confirm Password",
                    property: "confirmPassword",
                    type: "password",
                    required: true,
                    error: record.editRecord?.confirmPassword !== record.editRecord?.newPassword
                }
            ]}
            title="Change Password"
            saveLabel={"Update"}
            record={record}
            onChangeRecord={setRecord}
            onSaveClicked={submit}
        />
    )
}

export default ResetPassword;