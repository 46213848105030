import { Dialog } from "@mui/material";
import React from "react";
import * as loader from './assets/loader.json';
import Lottie from "lottie-react";
import { useSelector } from "react-redux";
import { apisLoadingSelector } from "./services/LoaderSlice";


const Loader = () => {

    const apisLoading = useSelector(apisLoadingSelector);

    return(
        <React.Fragment>
            <Dialog open={apisLoading.length > 0} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}>
                <Lottie animationData={loader} loop={true} />
            </Dialog>
        </React.Fragment>
    )
}

export default Loader;