import { Box } from "@mui/material";
import PropTypes from "prop-types";
import TemplateImageOverlay from "./TemplateImageOverlay";

const Overlay = ({color, details}) => {
    const styles = {
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: '0.9',
            background: color
        }
    }

    return(
        <Box style={styles.overlay}>
            {(() => {switch(details.selector){
                case "TemplateImage": 
                    return(<TemplateImageOverlay previewLink={details.link} onClickMethod={details.method}></TemplateImageOverlay>)
                default:
                    return(<Box></Box>)
            }})()}
        </Box>
    )
}

Overlay.propTypes = {
    color: PropTypes.string.isRequired,
    details: PropTypes.shape({
        selector: PropTypes.string.isRequired
    })
}

export default Overlay;