import PdfUpload from "../FileUpload/PdfUpload"
import ImageGallery from "../Gallery/ImageGallery";
import Review from "../Review";

const Steps = ({selector, successHandler, document, changeStep}) => {
    switch(selector){
        case "Upload":
            return( <PdfUpload nextStep={successHandler}/> )
        case "Template":
            return( <ImageGallery nextStep={successHandler}/> )
        case "Review":
            return( <Review nextStep={successHandler} queue={document.queue} changeStep={successHandler}/> )
        default:
            return( <Review nextStep={successHandler} queue={document.queue} changeStep={successHandler}/> )
    }
}

export default Steps;