import { createSlice } from "@reduxjs/toolkit";
import { HTTPRequest } from "../Api";

const RepoersSlice = createSlice({
    name: "reportsHandler",
    initialState: {
        reports: {}
    },
    reducers: {
        setReports: (state, action) => {
            state.reports = action.payload;
        }
    }
});

export function createReport(report, callback){
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/report/create', report, (response) => {
            if(typeof (callback) === 'function'){
                callback(response);
            }
        }))
    }
}

export const { setReports } = RepoersSlice.actions;

export const reportsSelector = (state) => state.reportsHandler.reports;

export default RepoersSlice.reducer;