import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import PVTable from "../../Custom/PVTable"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomDomain } from "../../services/TemplatesSlice";
import { templatesSelector } from "../../services/CreateTemplateSlice";

const CustomDomains = ({record, setRecord, addCustomDomain}) => {

    const [showInfo, setShowInfo] = useState(false);
    const [selectedData, setSelectedData] = useState();

    const templates = useSelector(templatesSelector);
    const selectedTemplate = templates.find(template => template._id === record?.template?._id);

    const dispatch = useDispatch();

    return(
        <Dialog open={record?.open} onClose={() => setRecord({})} maxWidth='lg'>
            <DialogTitle>{showInfo ? 'How to connect Domain' : 'Custom Domains (Click on show information for instructions)'}</DialogTitle>
            <DialogContent sx={{minHeight: {xs: '30vh', sm: '30vh', md: '100%'}}}>
                {!showInfo && <PVTable 
                    columns={[
                        {
                            label: 'Domain',
                            property: 'customDomain'
                        },
                        {
                            label: 'DNS verification',
                            property: 'canIssue',
                            calculated: (data) => data.canIssue ? 'Completed' : 'Pending'
                        },
                        {
                            label: 'Status',
                            property: 'issued',
                            calculated: (data) => data.issued ? 'Issued' : 'Requested'
                        }
                    ]}
                    data={selectedTemplate?.customDomains || []}
                    searchable
                    actions={[
                        {
                            label: 'Add New',
                            property: 'addNew',
                            onClick: () => addCustomDomain()
                        }
                    ]}
                    onRowClick={(data, key) => {
                        switch(key){
                            case 'showInfo':
                                setShowInfo(true);
                                setSelectedData(data);
                                break;
                            case 'delete':
                                dispatch(deleteCustomDomain(data._id));
                                break;
                            case 'open':
                                window.open(`https://${data.customDomain}`);
                                break;
                            default:
                                break;
                        }
                    }}
                    clickMenu={[
                        {
                            key: 'open',
                            label: 'Open',
                            enabled: (data) => data?.issued
                        },
                        {
                            key: 'delete',
                            label: 'Delete'
                        },
                        {
                            key: 'showInfo',
                            label: 'Show Information',
                        }
                    ]}
                    inPopup
                />}
                {showInfo && <ul>
                    <li>Open your DNS manager.</li>
                    <li>Create a CNAME record with name as <strong>{selectedData?.customDomain}</strong> and value as <strong>{selectedData?.checkCNAME}</strong>.</li>
                    <li>Verify that your changes are saved.</li>
                    <li>Wait for about 10 minutes for the changes to propogate.</li>
                    <li>Verify if your changes are propogated everywhere using <a href="dnschecker.com">dnschecker.com</a></li>
                </ul>}
                {showInfo && <DialogActions>
                    <Button onClick={() => {
                        setShowInfo(false);
                        setSelectedData({});
                    }}>Back</Button>
                </DialogActions>}
            </DialogContent>
        </Dialog>
    )

}

export default CustomDomains;