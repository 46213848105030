import { Button, Divider, Grid, Typography } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import { registrationForm } from "../../forms/registrationForm";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../services/UserSlice";
import PVForm from "../../Custom/PVForm";
import { screenWidthSelector } from "../../services/ScreenSlice";
import { theme } from "../../Theme";

const Register = ({animateFunction}) => {
    const styles = {
        description: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0 5vw',
            transition: 'display 1s ease'
        },
        formWrap:{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 5vw',
            transition: 'display 1s ease'
        },
        outlinedBtn: {
            margin: '3vw 0',
            background: 'transperent',
            color: theme.palette.primary.contrastText,
            border: `1px solid ${theme.palette.primary.contrastText}`
        }
    }

    const [registerFormData, setRegisterFormData] = useState({})
    const screenSize = useSelector(screenWidthSelector);

    const dispatch = useDispatch();

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: (response) => {handleGoogleSuccess(response)},
        onError: (error) => {console.log(error)}
    })

    const handleSignUp = () => {
        dispatch(signUp({...registerFormData, loginType: "EMAIL"}));   
        setRegisterFormData({});
    }

    const handleGoogleSuccess = (gData) => {
        const requestOptions = {
            method: 'GET',
            header: {
                Authorization: `Bearer ${gData.access_token}`,
                Accept: 'application/json'
            }
        }

        fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${gData.access_token}`, requestOptions).then((response)=> {
            response.json().then(data => {
                dispatch(signUp({
                    userEmail: data.email,
                    displayName: data.name,
                    loginType: "GMAIL"
                }))
            })
        })
    }


    return (
        <Grid container style={{position: 'relative', zIndex: 1, height: 'inherit'}}>
            <Grid item xs={12} sm={12} md={7} lg={7} style={styles.formWrap}>
                <Typography variant="h2">Create Your Account</Typography>
                <PVForm 
                    form={registrationForm} 
                    buttonText={'Sign Up'}
                    formData={registerFormData}
                    setFormData={setRegisterFormData} 
                    clickFunction={handleSignUp}/>
                <Divider width="100%"/>  
                <Button variant="outlined" style={{margin: '3vw 0'}} onClick={() => handleGoogleLogin()}>Sign up with Google</Button>
                {screenSize <= 900 && <Button style={{fontSize: 12, color: '#007BFF'}} variant="text" onClick={() => animateFunction()}>Login Instead</Button>}
            </Grid> 
            <Grid item xs={12} sm={12} md={5} lg={5} style={screenSize > 900 ? styles.description : {display: 'none'}}>
                <Typography variant="h4" style={{color: theme.palette.secondary.light}}>Already a user of P-viewer?</Typography>
                <Typography variant="h6" style={{color: theme.palette.secondary.main}}>Log in here to continue templating your ideas</Typography>
                <Button variant="outlined" onClick={() => animateFunction()} style={styles.outlinedBtn}>log in</Button>
            </Grid>
        </Grid>
    )
}

export default Register;