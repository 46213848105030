import { createSlice } from "@reduxjs/toolkit";
import { HTTPRequest } from "../Api";

const dashboardSlice = createSlice({
    name: "dashboardHandler",
    initialState: {
        analytics : {},
        templateViews: {}
    },
    reducers: {
        getAnalyticsReducer : (state, action) => {
            state.analytics = action.payload
        },
        setTemplateViews: (state, action) => {
            state.templateViews = action.payload;
        }
    }
})

export function getAnalytics (background){
    return (dispatch) => {
        dispatch(HTTPRequest('GET', `/user/analytics`, {}, (response) => {
            dispatch(getAnalyticsReducer(response.result));
            dispatch(setTemplateViews(response.templateViews));
        }, null, null, background))
    }
}


export const { getAnalyticsReducer, setTemplateViews } = dashboardSlice.actions;
export const getAnalyticsSelector = (state) => state.dashboardHandler.analytics;
export const templateViewsSelector = (state) => state.dashboardHandler.templateViews;
export default dashboardSlice.reducer;

