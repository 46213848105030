import { Box, Card, CardContent, CardHeader, Grid, Icon, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnalytics, getAnalyticsSelector } from "../../services/DashboardSlice";
import { keysToWords } from "../../Formatters/TextFormatters";
import ReactApexChart from "react-apexcharts";
import { WorldMap } from "react-svg-worldmap";
import { getMapData, mapDataSelector } from "../../services/TemplatesSlice";
import { theme } from "../../Theme";
import { Assessment, CoPresent, DomainVerification, PictureAsPdf } from "@mui/icons-material";

const Dashboard = () => {

    const analytics = useSelector(getAnalyticsSelector);
    const [graphData, setGraphData] = useState([]);
    // const templateViews = useSelector(templateViewsSelector);
    const mapData = useSelector(mapDataSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAnalytics());
        dispatch(getMapData());
    }, [dispatch])

    useEffect(() => {
        if(Boolean(analytics)){ 
            let data = [];
            Object.keys(analytics).filter(item => typeof(analytics[item]) === 'object').forEach(item => {
                let dataCategories = [];
                let dataSeries = [];
                if(Array.isArray(analytics[item])){
                    analytics[item].forEach(datapoint => {
                        dataCategories.push(datapoint.title || '');
                        dataSeries.push(datapoint.count || 0);
                    })
                    data.push({
                        categories: dataCategories,
                        series: dataSeries
                    })
                }
            })
            setGraphData(data);
        }
    }, [analytics])

    const getIcon = (item) => {
        switch(keysToWords(item)){
            case 'Active Templates':
                return <CoPresent />
            case 'Completed Assessments':
                return <Assessment />
            case 'Templates Created':
                return <CoPresent />
            case 'Custom Domains Connected':
                return <DomainVerification />
            case 'Cover Letters Generated':
                return <PictureAsPdf />
            case 'Assessments Taken':
                return <Assessment />
            default:
                return <Icon />
        }
    }

    return(
        <Box>
            <Grid container style={{justifyContent: 'center'}} spacing={2}>
                {Object.keys(analytics).filter(item => typeof(analytics[item]) !== 'object' && typeof(analytics[item]) !== 'string').map(item => (
                    <Grid item xs={12} md={4} xl={4}>
                        <Card style={{borderRadius: 20}}>
                            <CardHeader
                                avatar={
                                    <Icon>{getIcon(item)}</Icon>
                                }
                                title={keysToWords(item)}
                                titleTypographyProps={{align: 'center'}}
                            />
                            <CardContent>
                                <Typography variant="h3" align="center">
                                    {analytics[item]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Grid container style={{justifyContent: 'center'}}>
                {graphData.map(item => (
                    <Grid item xs={12} md={6} xl={6} style={{padding: 10}} sx={{minHeight: {xs: 400, sm: 350}}}>
                        <ReactApexChart
                            type="bar"
                            height={'100%'}
                            options={{
                                xaxis: {
                                    categories: item.categories
                                },
                                chart: {
                                    type: 'bar',
                                    toolbar: {
                                        show: false
                                    },
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: false,
                                        columnWidth: '55%',
                                        endingShape: 'rounded',
                                    }
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                stroke: {
                                    show: true,
                                    width: 2,
                                    colors: ['transparent']
                                },
                                colors: [theme.palette.secondary.main],
                                fill: {
                                    opacity: 1
                                },
                                tooltip: {
                                    y: {
                                        formatter: function (val) {
                                            return val + " views";
                                        }
                                    }
                                },
                                title: {
                                    text: 'Views'
                                }
                            }}
                            series={[{
                                data: item.series,
                                name: ''
                            }]}
                        />
                    </Grid>
                ))}
                {Boolean(mapData?.length) && <Grid item xs={12} md={6} xl={6} style={{padding: 0}} sx={{minHeight: {xs: 400, sm: 350}}}>
                    <h5>Sessions by country</h5>
                    <Grid>
                        <WorldMap 
                            color={theme.palette.primary.main} 
                            size="responsive" 
                            data={mapData}
                        />
                    </Grid>
                </Grid>}
            </Grid>
            {/* <Grid container>
                {templateViews.length > 0 && templateViews?.map(item => (
                    <Grid item xs={12} md={6} xl={4} style={{padding: 10}}>
                        <ReactApexChart
                            type="line"
                            options={{
                                xaxis: {
                                    categories: item.graphData.xAxis,
                                    type: 'datetime'
                                },
                                title: {
                                    text: `${item.title}(${item.subDomain})`
                                },
                                chart: {
                                    type: 'line'
                                },
                                plotOptions: {
                                    line: {
                                        curve: 'smooth'
                                    }
                                }
                            }}
                            series={[{
                                data: item.graphData.yAxis,
                                name: 'Count'
                            }]}
                        />
                    </Grid>
                ))}
            </Grid> */}
        </Box>
    )
}

export default Dashboard;