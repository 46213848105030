import { createSlice } from "@reduxjs/toolkit";
import { HTTPRequest } from "../Api";

const createTemplateSlice = createSlice({
    name: 'createTemplateHandler',
    initialState: {
        uploaded: false,
        uploadedDocuments: [],
        templates: [],
        uploadedDocumentId: null,
        unfinishedDoc: {}
    },
    reducers: {
        setUploaded: (state, action) => {
            state.uploaded = action.payload;
        },
        setUploadedDocuments: (state, action) => {
            state.uploadedDocuments = action.payload;
        },
        setTemplates: (state, action) => {
            state.templates = action.payload;
        },
        setUploadedDocumentId: (state, action) => {
            state.uploadedDocumentId = action.payload;
        },
        setUnfinishedDoc: (state, action) => {
            state.unfinishedDoc = action.payload;
        }
    }
});

export function uploadDocument(formdata, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/telegramdoc/uploadresume', formdata, (data) => {
            if(typeof(completed) === 'function'){
                completed();
                dispatch(setUploaded(true));
                dispatch(setUploadedDocumentId(data.documentId));
            }
        }, null, 'form'));
    }
}

export function selectTemplate(selectedTemplateId, documentId, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/telegramdoc/selecttemplate', {
            selectedTemplate: selectedTemplateId,
            documentId: documentId
        }, (data) => {
            if(typeof(completed) === 'function') {completed()}
        }))
    }
}

export function getDocuments() {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', '/telegramdoc/', {}, (response) => {
            dispatch(setUploadedDocuments(response))
        }))
    }
}

export function getTemplates(background) {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', '/templatedet/all', {}, (response) => {
            dispatch(setTemplates(response));
        }, null, null, background));
    }
}

export function editTemplate(templateId, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/templatedet/authforedit', {templateId}, (response) => {
            if(typeof(completed) === 'function'){
                completed(response);
            }
            dispatch(getTemplates());
        }))
    }
}

export function clearEditTemplate(templateId) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/templatedet/clearedit', {templateId}, (response) => {
            dispatch(getTemplates());
        }));
    }
}

export function deleteTemplate(templateId) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/templatedet/delete', {templateId}, (response) => {
            dispatch(getTemplates());
        }))
    }
}

export function toggleActive(templateId) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/templatedet/toggleactive', {templateId}, (response) => {
            dispatch(getTemplates());
        }))
    }
}

export function getUnfinishedDoc(completed, background) {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', '/telegramdoc/unfinishedDoc', {}, (response) => {
            if(typeof(completed) === 'function'){
                completed(response);
            }
            if(response._id !== undefined){
                dispatch(setUnfinishedDoc(response));
            }
        }, null, null, background));
    }
}

export function clearUnfinishedDoc(completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/telegramdoc/clearunfinished', {}, (response) => {
            if(typeof(completed) === 'function'){
                completed(response);
            }
            dispatch(setUnfinishedDoc({}));
        }))
    }
}

export const { setUploaded, setUploadedDocuments,
                setTemplates, setUploadedDocumentId,
                setUnfinishedDoc } = createTemplateSlice.actions;

export const uploadedDocumentsSelector = (state) => state.createTemplateHandler.uploadedDocuments;
export const templatesSelector = (state) => state.createTemplateHandler.templates;
export const uploadedDocumentIdSelector = (state) => state.createTemplateHandler.uploadedDocumentId;
export const unfinishedDocSelector = (state) => state.createTemplateHandler.unfinishedDoc;

export default createTemplateSlice.reducer;