import { useDispatch, useSelector } from "react-redux"
import { deleteEmail, getEmailById, getEmails, inboxSelector, readEmail, sendEmail, unreadEmail } from "../../services/InboxSlice"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from "@mui/material";
import PVTable from "../../Custom/PVTable";
import { useEffect, useState } from "react";
import PVForms from "../../Custom/PVForms";

const Inbox = () => {
    
    const dispatch = useDispatch();
    const inbox = useSelector(inboxSelector);
    const [tabValue, setTabValue] = useState(0);
    const [openEmail, setOpenEmail] = useState({});
    const [emailRecord, setEmailRecord] = useState({});
    const [filetredInbox, setFilteredInbox] = useState([]);

    const tabs = {
        0: ['applied'],
        1: ['interview', 'offer'],
        2: ['rejected'],
        3: 'all'
    }

    useEffect(() => {
        dispatch(getEmails());
    }, [dispatch]);

    useEffect(() => {
        if(tabValue === 3){
            setFilteredInbox(inbox);
        } else {
            setFilteredInbox(inbox.filter(email => tabs[tabValue].includes(email.emailType)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inbox, tabValue]);

    const onRowClick = (row, key) => {
        switch(key){
            case 'view':
                dispatch(getEmailById(row._id, (email) => {
                    setOpenEmail(email);
                }));
                dispatch(readEmail(row._id));
                break;
            case 'delete':
                dispatch(deleteEmail(row._id));
                break;
            case 'unread':
                dispatch(unreadEmail(row._id));
                break;
            case 'reply':
                setEmailRecord({
                    open: true,
                    replyEmail: true,
                    selectedEmail: row,
                    editRecord: {
                        to: row.from,
                        subject: `Re: ${row.subject}`
                    }
                })
                break;
            default:
                break;
        }
    }

    const sanatizeHtml = (html) => {
        return html?.replace(/<style.*?<\/style>/g, '');
    }
    
    const createMarkup = (html) => {
        return {__html: sanatizeHtml(html)};
    }

    return (
        <Box>
            <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
                <Tab label={`Applied(${inbox.filter(email => tabs[0].includes(email.emailType)).length})`} />
                <Tab label={`Accepted(${inbox.filter(email => tabs[1].includes(email.emailType)).length})`} />
                <Tab label={`Rejected(${inbox.filter(email => tabs[2].includes(email.emailType)).length})`} />
                <Tab label="All" />
            </Tabs>
            <Dialog open={Boolean(openEmail.from)} fullWidth maxWidth='md' onClose={() => setOpenEmail({})}>
                <DialogTitle>Subject: {openEmail.subject}</DialogTitle>
                <DialogContent
                    style={{ maxHeight: '60vh' }}>
                    {openEmail.html ? <iframe title="Email" srcDoc={createMarkup(openEmail.html).__html} style={{ width: '100%', height: '50vh', border: 'none' }} /> :
                    <div style={{whiteSpace: 'pre-line'}}>{openEmail.text}</div>}
                    {Boolean(openEmail.attachments) && <h4>Attachments</h4>}
                    {Boolean(openEmail.attachments) && openEmail.attachmentUrls.map((attachment, index) => (
                        <a key={index} href={attachment.url} download={attachment.name}>{attachment.name}</a>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setEmailRecord({
                            open: true,
                            replyEmail: true,
                            selectedEmail: openEmail,
                            editRecord: {
                                to: openEmail.from,
                                subject: `Re: ${openEmail.subject}`
                            }
                        })
                        setOpenEmail({});
                    }}>Reply</Button>
                    <Button onClick={() => setOpenEmail({})}>Close</Button>
                    <Button>Delete</Button>
                </DialogActions>
            </Dialog>
            <PVForms 
                fields={[
                    {
                        label: 'To',
                        property: 'to',
                        disabled: Boolean(emailRecord?.replyEmail),
                        required: true
                    },
                    {
                        label: 'Subject',
                        property: 'subject',
                        disabled: Boolean(emailRecord?.replyEmail),
                        required: true
                    },
                    {
                        label: 'Content',
                        property: 'text',
                        type: 'RICHTEXT',
                        required: true
                    },
                    {
                        label: 'Attachments',
                        property: 'attachments',
                        type: 'FILE'
                    }
                ]}
                record={emailRecord}
                onChangeRecord={setEmailRecord}
                saveLabel={'Send'}
                title={Boolean(emailRecord?.replyEmail) ? "Reply Email" : "Compose Email"}
                onSaveClicked={() => {
                    if(emailRecord?.replyEmail){
                        dispatch(sendEmail({
                            to: emailRecord?.editRecord?.to,
                            subject: emailRecord?.editRecord?.subject,
                            html: String(emailRecord?.editRecord?.text).replace(/<p><br><\/p>/g, '<br>').replace(/<p>/g, '').replace(/<\/?p>/g, '<br>'),
                            from: emailRecord?.selectedEmail?.to,
                            repliedTo: emailRecord?.selectedEmail?._id
                        }));
                    }
                }}
            />
            <PVTable 
                columns={[
                    {
                        label: 'Count',
                        property: 'count'
                    },
                    {
                        label: 'From',
                        property: 'from',
                        calculated: (row) => String(row?.from).includes('<') ? row?.from.split('<')[0] : row?.from,
                        maxLength: 50
                    },
                    {
                        label: 'Subject',
                        property: 'subject',
                        maxLength: 50
                    },
                    {
                        label: 'Date',
                        property: 'createdAt',
                        type: 'date'
                    },
                    {
                        label: 'Attachments',
                        property: 'attachments'
                    }
                ]}
                data={filetredInbox}
                searchable
                paginatied
                onRowClick={onRowClick}
                style={{marginTop: 10}}
                clickMenu={[
                    {
                        label: 'Reply',
                        key: 'reply'
                    },
                    {
                        label: 'View',
                        key: 'view'
                    },
                    {
                        label: 'Mark Unread',
                        key: 'unread'
                    },
                    {
                        label: 'Delete',
                        key: 'delete'
                    }
                ]}
                actions={[
                    {
                        label: 'Refresh',
                        onClick: () => dispatch(getEmails()),
                        property: 'refresh'
                    },
                    {
                        label: 'New Email',
                        onClick: () => setEmailRecord({
                            open: true,
                            replyEmail: false
                        }),
                        property: 'refresh'
                    }
                ]}
                rowStyle={(row) => {
                    if (!row.read) {
                        return { backgroundColor: 'rgba(211, 211, 211, 0.5)' }; // Light shade of gray for unread status
                    }
                }}
            />
        </Box>
    )

}

export default Inbox;