import { createSlice } from "@reduxjs/toolkit";
import { HTTPRequest } from "../Api";

const inboxSlice = createSlice({
    name: "inboxHandler",
    initialState: {
        inbox: [],
        currentEmail: {}
    },
    reducers: {
        setInbox: (state, action) => {
            state.inbox = action.payload;
        },
        setCurrentEmail: (state, action) => {
            state.currentEmail = action.payload;
        }
    }
})

export function getEmails() {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', '/email', {}, (response) => {
            dispatch(setInbox(response));
        }))
    }
}

export function getEmailById(id, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', `/email/${id}`, {}, (response) => {
            if(typeof(completed) === 'function'){
                completed(response);
            }
            dispatch(setCurrentEmail(response));
        }))
    }
}

export function sendEmail(data) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/email/send', data, (response) => {
            dispatch(getEmails());
        }));
    }
}

export function readEmail(id) {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', `/email/read/${id}`, {}, (response) => {
            dispatch(getEmails());
        }));
    }
}

export function unreadEmail(id) {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', `/email/unread/${id}`, {}, (response) => {
            dispatch(getEmails());
        }));
    }
}

export function deleteEmail(id) {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', `/email/delete/${id}`, {}, (response) => {
            dispatch(getEmails());
        }));
    }
}

export const { setInbox, setCurrentEmail } = inboxSlice.actions;
export const inboxSelector = (state) => state.inboxHandler.inbox;
export const currentEmailSelector = (state) => state.inboxHandler.currentEmail;
export default inboxSlice.reducer;