import './App.css';
import { Box } from '@mui/material';
import Navigation from './components/Navigation/Navigation';
import Dashboard from "./components/Dashboard";
import Templates from './components/Templates/Templates';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Stepper from './components/Stepper/Stepper';
import Assessment from './components/Assessment';
import Notifications from './Notifications';
import QandA from './components/Assessment/QandA';
import Analytics from './components/Assessment/Analytics';
import AuthModule from './components/Authentication/AuthCard';
import Settings from './components/Dialogs/Settings';
import CoverLetters from './components/CoverLetters';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUserData, userSelector, verifyEmail } from './services/UserSlice';
import Applications from './components/Applications';
import Loader from './Loader';
import { setEnv, setScreenHeight, setScreenWidth } from './services/ScreenSlice';
import { io } from 'socket.io-client';
import { handleSocketData } from './helpers/SocketHandler';
import Inbox from './components/Inbox';

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  
  window.addEventListener('resize', () => {
    dispatch(setScreenWidth(window.innerWidth));
    dispatch(setScreenHeight(window.innerHeight));
  })

  useEffect(() => {
    if(window.location.hostname === 'portal.resumlink.com'){
      dispatch(setEnv('prod'));
    } else {
      dispatch(setEnv('test'));
    }
    if(user.userId !== undefined){
      let backend = 'https://api.resumlink.com';
      if(window.location.hostname !== 'portal.resumlink.com'){
        backend = 'https://testbe.tedoratech.com';
      }
      const socket = io(backend, {
        path: '/pviewerApi/socket.io',
        transports: ['websocket'],
        query:{
          userId: user?.userId
        }
      });

      socket.on('message', (data) => {
        handleSocketData(dispatch, data);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user])

  useEffect(() => {
    if(user.userId === undefined){
      dispatch(getUserData(user));
    }
    if(window.location.hostname === 'portal.resumlink.com'){
      dispatch(setEnv('prod'));
    }
    dispatch(setScreenWidth(window.innerWidth));
    dispatch(setScreenHeight(window.innerHeight));
  }, [dispatch, user])

  const checkActiveSession = () => {
    let accessToken = localStorage.getItem("accessToken");
    let searchParam = new URLSearchParams(window.location.search);
    if(searchParam.has('token')){
      dispatch(verifyEmail(searchParam.get('token'), () => {
        navigate('/');
      }));
      return false;
    }
    if(accessToken === 'null' || accessToken === null || accessToken === undefined){
      navigate('/');
      return false;
    }
    return true;
  }

  return (
    checkActiveSession() ? 
    <Box sx={{display: 'flex'}}>
      <Navigation />
      <Notifications />
      <Settings />
      <Loader />
      <Box sx={{flexGrow: 1, width: {sm: 'calc(100% - 300px)', xs: '100vw'}, p:3, marginTop: {sm: '7vh', md: '7vh', xs: '8vh'}}}>
        <Routes>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/templates" element={<Templates />}></Route>
          <Route path="/inbox" element={<Inbox />}></Route>
          <Route path="/create" element={<Stepper />}></Route>
          <Route path='/assessment' element={<Assessment />}></Route>
          <Route path='/qa' element={<QandA />}></Route>
          <Route path='/analytics' element={<Analytics />}></Route>
          <Route path='/cl' element={<CoverLetters />}></Route>
          <Route path='/applications' element={<Applications />}></Route>
        </Routes>
      </Box>
    </Box> 
    : 
    <>
      <Notifications />
      <AuthModule />
    </>
  );
}

export default App;
