import { Box, Button, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadDocument } from '../../services/CreateTemplateSlice';
import { theme } from '../../Theme';

const PdfUpload = ({nextStep}) => {
    const inputRef = useRef(null)

    const [uploadStatus, setUploadStatus] = useState('neutral')
    const [text, setText] = useState('Drag your file here to upload');
    const dispatch = useDispatch();

    const styles = {
       dropContainer: {
        padding: '15vw',
        border: `2px dashed ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: '20px',
       },
       errorContainer:{
        padding: '15vw',
        border: `2px dashed ${theme.palette.error.main}`,
        borderRadius: '20px'
       },
       successContainer:{
        padding: '15vw',
        border: `2px dashed ${theme.palette.success.main}`,
        borderRadius: '20px'
       },
       uploadButton: {
        border: `2px solid ${theme.palette.primary.main}`,
       }
    }

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(e.type === "dragover"){
            if(e.dataTransfer.items.length > 1){
                setUploadStatus('error')
                setText('Please upload only one file')
            }else if(Array.from(e?.dataTransfer?.items)[0]?.type !== undefined && Array.from(e?.dataTransfer?.items)[0]?.type !== 'application/pdf'){
                setUploadStatus('error')
                setText('Please upload only PDF files')
            }else{
                setUploadStatus('success')
            }
        }
    }

    const handleDrop = (e) => {
        e.preventDefault();

        setUploadStatus('neutral')
        setText('Drag your file here to upload')

        if(e.dataTransfer.files.length > 0){
            if(e.dataTransfer.files.length === 1 && e.dataTransfer.files[0].type === 'application/pdf'){
                const file = e.dataTransfer.files[0];
                const formData = new FormData();
                formData.append('file', file);
                dispatch(uploadDocument(formData, () => {
                    nextStep();
                }))
            }
        }
    }

    const handleFileSelection = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        dispatch(uploadDocument(formData, () => {
            nextStep();
        }))
    }

    const handleClick = () => {
        inputRef.current.click();
    }

    return(
        <Box style={uploadStatus === 'neutral' ? styles.dropContainer : (uploadStatus === 'success' ? styles.successContainer : styles.errorContainer)} onDragEnter={handleDrag} onDragOver={handleDrag} onDragLeave={handleDrag} onDrop={handleDrop}>
            <Typography variant='h5' style={{textAlign: 'center', paddingBottom: '1vw'}}>{text}</Typography>
            <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button style={styles.uploadButton} onClick={handleClick}>Choose File</Button>
                <input ref={inputRef} multiple={false} type="file" style={{display: 'none'}} accept='application/pdf' onChange={handleFileSelection}></input>
            </Box>
        </Box>
    )
}

export default PdfUpload;