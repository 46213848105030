import { createSlice } from "@reduxjs/toolkit";
import { HTTPRequest } from "../Api";
import { setBody, setType } from "./NotificationSlice";

const userSlice = createSlice({
    name: 'userHandler',
    initialState: {
        user: {}
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        }
    }
})

export function signUp(data) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/user/signup', data, (response) => {
            if(response.token){
                localStorage.setItem("accessToken", response.token);
                window.location.reload();
            }
            dispatch(setUser(response.user));
        }))
    }
}

export function login(data) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/user/login', data, (response) => {
            if(response.token){
                localStorage.setItem("accessToken", response.token);
                window.location.reload();
            }
            dispatch(setUser(response.user));
        }))
    }
}

export function resetPassword(body, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/user/resetpwd', body, (response) => {
            if(typeof(completed) === 'function'){
                completed();
            }
            dispatch(getUserData());
            dispatch(setType('success'));
            dispatch(setBody(response.message));
        }))
    }
}

export function forgotPassword(body, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/user/forgotpwd', body, (response) => {
            if(typeof(completed) === 'function'){
                completed();
            }
            dispatch(setType('success'));
            dispatch(setBody('A temporary password has been sent to your email.'));
        }))
    }

}

export function getUserData() {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', '/user', {}, (response) => {
            dispatch(setUser(response));
        }))
    }
}

export function verifyEmail(token, succesHandler){
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/user/verifyemail`, {token}, (response) => {
            dispatch(setType('success'));
            dispatch(setBody('Email Verified Successfully!'));
            if(typeof(succesHandler) === 'function'){
                succesHandler();
            }
        }))
    }
}

export const { setUser } = userSlice.actions;

export const userSelector = (state) => state.userHandler.user;

export default userSlice.reducer; 