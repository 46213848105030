import { getCoverLetterRequests } from "../services/CoverLettersSlice";
import { getTemplates, getUnfinishedDoc } from "../services/CreateTemplateSlice";
import { getAnalytics } from "../services/DashboardSlice";
import { getEmails } from "../services/InboxSlice";
import { getMapData } from "../services/TemplatesSlice";

export function handleSocketData(dispatch, data) {
  const location = window.location.pathname;
  switch(data.type){
    case "coverLetter":
      if(location === '/cl'){
        dispatch(getCoverLetterRequests(true));
      }
      break;
    case 'templateDetails':
      if(location === '/templates' || location === '/create'){
        dispatch(getTemplates(true));
      }
      break;
    case 'session':
      if(location === '/templates'){
        dispatch(getTemplates(true));
      }
      if(location === '/'){
        dispatch(getAnalytics(true));
        dispatch(getMapData(true));
      }
      break;
    case 'unfinshedDoc':
      if(location === '/create'){
        dispatch(getUnfinishedDoc(null, true));
      }
      break;
    case 'email':
      if(location === '/inbox'){
        dispatch(getEmails());
      }
      break;
    default:
      break;
  }
}