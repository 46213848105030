import { Grid, Tab, Tabs } from "@mui/material";
import PVTable from "../../Custom/PVTable";
import PVForms from "../../Custom/PVForms";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applicationsSelector, createApplication, deleteApplication, getApplications, updateApplication } from "../../services/ApplicationsSlice";
import { userSelector } from "../../services/UserSlice";
import { Add, Refresh } from "@mui/icons-material";

const Applications = () => {

    const [newApplicationRecord, setNewApplicationRecord] = useState({});
    const [notesRecord, setNotesRecord] = useState({});
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useDispatch();

    const applications = useSelector(applicationsSelector);
    const user = useSelector(userSelector);

    const statusList = [
        {
            label: 'Not Applied',
            value: 'notApplied'
        }, 
        {
            label: 'Applied',
            value: 'applied'
        },
        {
            label: 'Interview',
            value: 'interview'
        },
        {
            label: 'Offer',
            value: 'offer'
        },
        {
            label: 'Rejected',
            value: 'rejected'
        }
    ];

    const onRowClick = (data, key) => {
        switch(key){
            case 'downloadResume':
                window.open(data.resumeUrl);
                break;
            case 'downloadCl':
                window.open(data.cvlUrl);
                break;
            case 'edit':
                setNewApplicationRecord({open: true, editRecord: data});
                break;
            case 'notes':
                setNotesRecord({open: true, editRecord: {id: data._id, notes: data.notes || ''}});
                break;
            case 'delete':
                dispatch(deleteApplication(data._id));
                break;
            case 'archive':
                dispatch(updateApplication(data._id, {...data, archived: true}));
                break;
            case 'unarchive':
                dispatch(updateApplication(data._id, {...data, archived: false}));
                break;
            default:
                console.log("No Implementation for", key);
        }
    }

    useEffect(() => {
        dispatch(getApplications());
    }, [dispatch])

    const create = () => {
        dispatch(createApplication(newApplicationRecord.editRecord, () => setNewApplicationRecord({})));
    }

    const update = () => {
        dispatch(updateApplication(notesRecord.editRecord.id, notesRecord.editRecord, () => setNotesRecord({})));
    }

    const updateData = () => {
        dispatch(updateApplication(newApplicationRecord.editRecord._id, newApplicationRecord.editRecord, () => setNewApplicationRecord({})));
    }   
    
    return(
        <Grid>
            <PVForms
                fields={[
                    {
                        label: 'Notes',
                        property: 'notes',
                        required: true,
                        type: 'RICHTEXT',
                        rows: 10
                    }
                ]}
                record={notesRecord}
                onChangeRecord={setNotesRecord}
                saveLabel={'Add notes'}
                onSaveClicked={update}
            />
            <PVForms 
                fields={[
                    {
                        label: 'Job Title',
                        property: 'jobTitle',
                        required: true
                    },
                    {
                        label: 'Company Name',
                        property: 'companyName',
                        required: true
                    },
                    {
                        label: 'Status',
                        property: 'status',
                        required: true,
                        type: 'SELECT',
                        listValues: statusList,
                        listLabelKey: 'label',
                        listValueKey: 'value'
                    },
                    {
                        label: 'Date Applied',
                        property: 'dateApplied',
                        type: 'DATE',
                        required: true
                    },
                    {
                        label: 'Date Opened',
                        property: 'dateOpened',
                        type: 'DATE'
                    },
                    {
                        label: 'Upload Resume',
                        property: 'resumeUrl',
                        type: 'FILE'
                    },
                    {
                        label: 'Upload Cover Letter',
                        property: 'cvlUrl',
                        type: 'FILE'
                    }
                ]}
                record={newApplicationRecord}
                onChangeRecord={setNewApplicationRecord}
                title={Boolean(newApplicationRecord?.editRecord?._id) ? "Edit Application" : "Add New Application"}
                saveLabel={Boolean(newApplicationRecord?.editRecord?._id) ? 'Edit' : 'Create'}
                onSaveClicked={Boolean(newApplicationRecord?.editRecord?._id) ? updateData : create}
            />
            <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
                <Tab label="Active" />
                <Tab label="Archived" />
            </Tabs>
            <PVTable 
                style={{marginTop: 20}}
                columns={[
                    {
                        label: 'Count',
                        property: 'count'
                    },
                    {
                        label: 'Job Title',
                        property: 'jobTitle'
                    },
                    {
                        label: 'Company',
                        property: 'companyName'
                    },
                    {
                        label: 'Status',
                        property: 'status',
                        calculated: (row) => {
                            const status = statusList.find((status) => status.value === row.status);
                            return status?.label || 'Not Applied';
                        }
                    },
                    {
                        label: 'Date Applied',
                        property: 'dateApplied',
                        type: 'date'
                    },
                    {
                        label: 'Date Opened',
                        property: 'dateOpened',
                        type: 'date'
                    },
                    {
                        label: 'Last Updated',
                        property: 'updatedAt',
                        type: 'dateTime'
                    }
                ]}
                searchable
                data={tabValue === 0 ? applications.filter((application) => !application.archived) : applications.filter((application) => application.archived)}
                actions={[
                    {
                        label: 'Refresh',
                        property: 'refresh',
                        onClick: () => dispatch(getApplications()),
                        icon: <Refresh />
                    },
                    {
                        label: 'Create New',
                        property: 'createNew',
                        onClick: () => setNewApplicationRecord({open: true, editRecord: {userId: user.userId, dateApplied: new Date(), status: 'notApplied', dateOpened: new Date()}}),
                        icon: <Add />
                    }
                ]}
                clickMenu={[
                    {
                        key: 'downloadResume',
                        label: 'Download Resume',
                        enabled: (row) => Boolean(row?.resumeUrl)
                    },
                    {
                        key: 'downloadCl',
                        label: 'Download CL',
                        enabled: (row) => Boolean(row?.cvlUrl)
                    },
                    {
                        key: 'edit',
                        label: 'Edit'
                    },
                    {
                        key: 'notes',
                        label: 'Notes'
                    },
                    {
                        key: 'delete',
                        label: 'Delete'
                    },
                    {
                        key: 'archive',
                        label: 'Archive',
                        visible: (row) => !row?.archived
                    },
                    {
                        key: 'unarchive',
                        label: 'Unarchive',
                        visible: (row) => row?.archived
                    }
                ]}
                onRowClick={onRowClick}
                paginatied
            />
        </Grid>
    )
}

export default Applications;