import { Launch, QueuePlayNext } from "@mui/icons-material";
import { Box, Button, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { theme } from "../../Theme";

const TemplateImageOverlay = ({previewLink, onClickMethod}) => {
    const styles = {
        btnWrap: {
            display: 'flex', 
            height: '100%', 
            alignItems: 'center', 
            justifyContent: 'center', 
            transition: 'all ease 0.5s'
        },
        btn: {
            minWidth: '150px', 
            fontWeight: 'bolder', 
            maxHeight: '30px', 
            padding: '20px',
            textTransform: 'capitalize',
            transition: 'font-size ease 0.5s',
            borderRadius: 0
        }
    }

    const [buttonHover, setButtonHover] = useState('');

    const openTemplate = (link) => {
        window.open(link)
    }

    return(
        <Box style={styles.btnWrap}>
            <Button variant="text" 
                style={{...styles.btn, ...{fontSize: buttonHover === 'choose' ? '22px' : '16px', color: theme.palette.primary.main,  borderRight: `2px solid ${theme.palette.primary.main}`}}} 
                endIcon={<QueuePlayNext style={{fontSize: '22px', margin: '-10px'}}/>} 
                onMouseEnter={() => setButtonHover('choose')} 
                onMouseLeave={() => setButtonHover('')}
                onClick={onClickMethod}
            >
                    Choose
            </Button>
            <Tooltip title="Open template in new tab">
                <Button variant="text" 
                    style={{...styles.btn, ...{fontSize: buttonHover === 'preview' ? '22px' : '16px'}}} 
                    endIcon={<Launch style={{fontSize: '22px', margin: '-10px'}}/>} 
                    onMouseEnter={() => setButtonHover('preview')} 
                    onMouseLeave={() => setButtonHover('')}
                    onClick={() => openTemplate(previewLink)}
                >
                        Preview
                </Button>
            </Tooltip>
        </Box>
    )
}

TemplateImageOverlay.propTypes = {
    previewLink : PropTypes.string.isRequired,
    onClickMethod : PropTypes.func.isRequired
}

export default TemplateImageOverlay;