import { Button, Grid } from "@mui/material"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { assessmentIdSelector, getAssessmentQuestions, questionsSelector, sendEvent, syncAnswers } from "../../services/AssessmentSlice";
import CustomFields from "../CustomFields/CustomFields";
import { useNavigate } from "react-router-dom";

const QandA = () => {

    const dispatch = useDispatch();
    const [seconds, setSeconds] = useState(0);
    const questions = useSelector(questionsSelector);
    const assessmentId = useSelector(assessmentIdSelector);
    const navigate = useNavigate();

    const [answers, setAnswers] = useState({});
    
    useEffect(() => {
        if(assessmentId){
            dispatch(getAssessmentQuestions(assessmentId));
        }
    }, [dispatch, assessmentId])

    useEffect(() => {
      setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }, [dispatch])

    const secondsToHMS = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
    
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    return(
        <div>
            {Object.keys(questions)?.length > 0 ? 
              <Grid>
                  <h2 style={{textAlign: 'center'}}>Assessment Questions</h2>
                  <h2 style={{position: 'fixed', right: 30, top: 70, float: 'right'}}>{secondsToHMS(seconds)}</h2>
                  {Object.keys(questions).map((question, i) => (
                    <div key={i} style={{padding: 25}}>
                        <h4>{questions[question]}</h4>
                        <CustomFields 
                            fieldDetails={{
                                property: `Answer${i}`,
                                label: 'Answer' ,
                                name: 'Answer',
                                type: 'text',
                                multiline: true,
                                rows: 5,
                                focusEvents: (property, event) => dispatch(sendEvent(assessmentId, event, property))
                            }}  
                            value={answers[question] || ''}
                            formChangeFunction={(e, p) => setAnswers({...answers, [question]: e.target.value})}
                        />
                    </div>
                ))}
                <Grid style={{padding: 20, textAlign: 'center'}}>
                  <Button variant="contained" onClick={() => {
                    dispatch(syncAnswers(assessmentId, answers))
                    navigate('/assessment');
                  }}>Submit</Button>
                </Grid>
              </Grid>
            : 
              <Grid>
                <h2 style={{textAlign: 'center'}}>Assessment not found</h2>
              </Grid>
            }
        </div>
    )
}

export default QandA;