import { createSlice } from "@reduxjs/toolkit";
import { HTTPRequest } from "../Api";

const templateSlice = createSlice({
    name: 'templateHandler',
    initialState: {
        template: {},
        document: {},
        mapData: []
    },
    reducers: {
        setTemplate: (state, action) => {
            state.template = action.payload;
        },
        setDocument: (state, action) => {
            state.document = action.payload;
        },
        setMapData: (state, action) => {
            state.mapData = action.payload;
        }
    }
})

export function changeSubDomain(templateId, subDomain, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/templatedet/changedomain/${templateId}`, {subDomain}, () => {
            if(typeof(completed) === 'function') {
                completed();
            }
        }))
    }
}

export function connectCustomDomain(templateId, requestData, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/templatedet/customdomain/${templateId}`, requestData, () => {
            if(typeof(completed) === 'function'){
                completed();
            }
        }))
    }
}

export function deleteCustomDomain(domainId, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/templatedet/deletecert/${domainId}`, {}, () => {
            if(typeof(completed) === 'function'){
                completed();
            }
        }))
    }
}

export function getDocument(documentId, completed) {
    return (dispatch) => {
        dispatch(HTTPRequest('GET', `/telegramdoc/getdoc/${documentId}`, {}, (data) => {
            if(typeof(completed) === 'function'){
                completed(data);
            }
            dispatch(setDocument(data));
        }))
    }
}

export function getMapData(background){
    return (dispatch) => {
        dispatch(HTTPRequest('GET', '/session/getmapdata', {}, (data) => {
            dispatch(setMapData(data));
        }, null, null, background))
    }
}

export const { setTemplate, setDocument, setMapData } = templateSlice.actions;

export const templateSelector = (state) => state.templateHandler.template;
export const documentSelector = (state) => state.templateHandler.document;
export const mapDataSelector = (state) => state.templateHandler.mapData;

export default templateSlice.reducer;