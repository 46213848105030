import { Box, Button, Card, CardActions, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Hidden, IconButton, Tooltip, Typography } from "@mui/material"
import { Analytics, Cancel, ContentCopy, Delete, Edit, OpenInNew } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { clearEditTemplate, deleteTemplate, editTemplate, getTemplates, templatesSelector, toggleActive } from "../../services/CreateTemplateSlice";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import { setBody, setType } from "../../services/NotificationSlice";
import PVConfirm from "../../Custom/PVConfirm";
import QRCode from "react-qr-code";
import PVForms from "../../Custom/PVForms";
import { changeSubDomain, connectCustomDomain } from "../../services/TemplatesSlice";
import CustomDomains from "./CustomDomains";

const Templates = () => {

    const dispatch = useDispatch();
    const templates = useSelector(templatesSelector);
    const navigate = useNavigate();

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [openQr, setOpenQr] = useState(false);
    const [editRecord, setEditRecord] = useState({open: false});
    const [customDomainRecord, setCustomDomainRecord] = useState({});
    const [customDomains, setCustomDomains] = useState({});

    useEffect(() => {
        dispatch(getTemplates());
    }, [dispatch])

    const textWrap = (text, maxLength) => {
        if(typeof(maxLength) !== 'number'){
            maxLength = 25
        }
        if(typeof(text) === 'string'){
            if(String(text).length > maxLength){
                return String(text).substring(0, maxLength - 1) + "...";
            } else {
                return text;
            }
        } else {
            return '';
        }
    }

    return(
        <Box>
            <PVConfirm 
                open={confirmOpen}
                title={'Confirm'}
                confirmMessage={'Are you sure you want to delete the template ?'}
                cancelClicked={() => {
                    setConfirmOpen(false)
                    setSelectedTemplate({});
                }}
                saveClicked={() => {
                    setConfirmOpen(false);
                    dispatch(deleteTemplate(selectedTemplate._id));
                    setSelectedTemplate({});
                }}
            />
            <PVForms
                record={editRecord}
                onChangeRecord={setEditRecord}
                title="Edit URL"
                saveEnabled={true}
                fields={[
                    {
                        label: 'Sub Domain',
                        property: 'subDomain',
                        error: !(Boolean(editRecord?.editRecord?.subDomain?.endsWith('testsystems.tedoratech.com')) || 
                        Boolean(editRecord?.editRecord?.subDomain?.endsWith('profilelink.click')))
                    }
                ]}
                onSaveClicked={(record) => {
                    dispatch(changeSubDomain(record.editRecord._id, record.editRecord.subDomain, () => {
                        setEditRecord({});
                        dispatch(getTemplates());
                    }))
                    if(Boolean(record.editRecord.customDomain)){
                        dispatch(connectCustomDomain(record.editRecord._id, record.editRecord, () => {
                            setEditRecord({});
                            dispatch(getTemplates());
                        }))
                    }
                }}
                onCancelClicked={() => setEditRecord({open: false})}
            />
            <PVForms 
                record={customDomainRecord}
                onChangeRecord={setCustomDomainRecord}
                title="Add Cusom Domains"
                fields={[
                    {
                        label: 'Custom Domain',
                        property: 'customDomain',
                        required: true
                    }
                ]}
                onCancelClicked={() => {
                    setCustomDomainRecord({open: false})
                    setCustomDomains({...customDomains, open: true});
                }}
                onSaveClicked={(record) => {
                    if(Boolean(record.editRecord.customDomain)){
                        dispatch(connectCustomDomain(record.editRecord._id, record.editRecord, () => {
                            setCustomDomainRecord({});
                            setCustomDomains({...customDomains, open: true});
                            dispatch(getTemplates());
                        }))
                    }
                }}
            />
            <CustomDomains record={customDomains} setRecord={setCustomDomains} addCustomDomain={() => {
                setCustomDomainRecord({open: true, editRecord: customDomains.template})
                setCustomDomains({...customDomains, open: false})
            }}/>
            <Dialog open={openQr}>
                <DialogTitle variant="h7" style={{textAlign: 'center'}}>Your websites URL</DialogTitle>
                <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 30}}>
                    <QRCode 
                        value={`https://${selectedTemplate.subDomain}`}
                        style={{marginLeft: 30, marginRight: 30}}
                    />
                    <Typography variant="body2">Use your phone camera to scan this code</Typography>
                </DialogContent>
                <DialogActions>
                    <Button style={{}} onClick={() => setOpenQr(false)}>Cancel</Button>
                    <Button style={{}} endIcon={<OpenInNew />} onClick={() => window.open(`https://${selectedTemplate.subDomain}`)}>Open URL</Button>
                </DialogActions>
            </Dialog>
            <Hidden xlDown={templates.length > 0}>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <Typography variant="h1">You currently have no templates</Typography>
                    <Typography variant="body1">Click the button below to create a new template</Typography>
                    <Button color="primary" onClick={() => navigate('/create')} style={{ marginTop: '20px' }}>Create Template</Button>
                </div>
            </Hidden>
            <Grid container style={{display: 'flex'}}>
                {templates.map(template => (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3} style={{padding: 10, flexGrow: 'initial'}}>
                        <Card sx={{height: 'auto'}}>
                            <div style={{position: 'relative'}}>
                                <CardMedia 
                                    component={'img'}
                                    alt="template"
                                    height={200}
                                    image={template.screenshotUrl || 'https://profileviewerapp.s3.us-east-2.amazonaws.com/1582477510/64d6859f493db555a468ec79/logo-white.png'}
                                />
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Background color for the icon
                                    }}
                                    onClick={() => window.open(`https://${template.subDomain}`)}
                                    >
                                    <OpenInNew />
                                </IconButton>
                            </div>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            <Grid style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <Tooltip title={Boolean(template.active) ? 'Hooray!! Your site is online' : 'Click to make the site active'}>
                                                    <div style={{width: 15, height: 15, borderRadius: '50%', backgroundColor: Boolean(template.active) ? '#00FF00' : '#FF0000',
                                                                cursor: 'pointer'}} 
                                                        onClick={() => dispatch(toggleActive(template._id))}/>
                                                </Tooltip>
                                                <span style={{marginLeft: 10}}>
                                                    {textWrap(template.title, 10)}
                                                </span>
                                            </Grid>
                                        </Typography>
                                        <Typography variant="body1">
                                            {textWrap(template?.documentData?.metaData?.CONTACT_INFORMATION?.phoneNumber)}
                                        </Typography>
                                        <Typography variant="body1">
                                            {textWrap(template?.documentData?.metaData?.CONTACT_INFORMATION?.email)}
                                        </Typography>
                                        <Typography variant="body2" style={{fontSize: 14, textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                                            window.open(`https://${template.subDomain}`);
                                        }}>
                                            {textWrap(template.subDomain, 20)}
                                            <Edit style={{fontSize: 14, marginLeft: 10, marginTop: 2}} onClick={(event) => {
                                                event.stopPropagation();
                                                console.log(template);
                                                setEditRecord({open: true, editRecord: template})
                                            }}/>
                                        </Typography>
                                        <Typography variant="body2" style={{fontSize: 12}}>
                                            {textWrap('Updated - ' + new Date(template.updatedAt).toLocaleString('default', {month: 'short', day: 'numeric', year: 'numeric'}))}
                                        </Typography>
                                        <Typography variant="body2" style={{fontSize: 14, textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                                            setCustomDomains({open: true, data: template.customDomains, template: template})
                                        }}>
                                            {'Custom Domains(Premium)'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title="Total Visitors">
                                            <div style={{ width: 60, height: 60,borderRadius: '50%',background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                                                    display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '20px', color: '#FFF' }}
                                                >
                                                <Typography>{template?.sessions}</Typography>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Hidden xlDown={Boolean(template?.authToken)}>
                                    <Tooltip title="View Analytics">
                                        <IconButton onClick={() => navigate('/')}><Analytics /></IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit">
                                        <IconButton onClick={() => dispatch(editTemplate(template._id, (res) => {
                                                window.open(`https://${template.subDomain}?authToken=${res.authToken}`);
                                        }))}><Edit /></IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton onClick={() => {
                                            setSelectedTemplate(template);
                                            setConfirmOpen(true);
                                        }}><Delete /></IconButton>
                                    </Tooltip>
                                    <Tooltip title="Copy Link">
                                        <IconButton onClick={() => {
                                            copy("https://" + template.subDomain)
                                            setSelectedTemplate(template);
                                            setOpenQr(true);
                                            dispatch(setBody('Copied successully'));
                                            dispatch(setType('success'))
                                        }}>
                                            <ContentCopy />
                                        </IconButton>
                                    </Tooltip>
                                </Hidden>
                                <Hidden xlDown={!Boolean(template?.authToken)}>
                                    <Button
                                        variant="text"
                                        startIcon={<Cancel />}
                                        onClick={() => {
                                            dispatch(clearEditTemplate(template._id))
                                        }}
                                    >
                                        Clear Edit
                                    </Button>
                                </Hidden>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default Templates;