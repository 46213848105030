export const loginForm = [
    {
        "selector": "TEXTFIELD",
        "label": "Email",
        "name": "userEmail",
        "type": "text",
        "required": true,
        "validation": /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/,
        "shrink": true
    },
    {
        "selector": "TEXTFIELD",
        "label": "Password",
        "name": "password",
        "type": "password",
        "required": true,
        "shrink": true
    }
]