export const navItmes = [
    {
        name : "Dashboard",
        link : "/"
    },
    {
        name : "Templates",
        link : "/templates"
    },
    {
        name : "Create",
        link : "/create"
    },
    {
        name : "Inbox",
        link : "/inbox"
    },
    {
        name: "Assessment",
        link: "/assessment"
    },
    {
        name: "Cover Letter",
        link: "/cl"
    },
    {
        name: "Applications",
        link: '/applications'
    }
]